<script>

import { mapGetters } from 'vuex'
import api from '@/api'

export default {
  name: 'PromptTab',
  props: {
    generationParams: {
      type: Object,
      required: true
    },
    messages: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION'])
  },
  data() {
    return {
      modelsList: [],
    }
  },
  mounted() {
    api.get('/generator/models').then(({ data }) => {
      this.modelsList = data
    })
  }
}
</script>

<template>
  <b-tab v-if="HAS_PERMISSION('generators_prompt')" title="Промпты">
    <b-form-group>
      <b-input-group>
        <b-input-group-prepend is-text>
          System prompt
        </b-input-group-prepend>
        <b-form-textarea v-model.trim="messages.system" rows="2"/>
      </b-input-group>
    </b-form-group>

    <b-form-group>
      <b-input-group>
        <b-input-group-prepend is-text>
          User prompt
        </b-input-group-prepend>
        <b-form-textarea v-model.trim="messages.user" rows="15"/>
      </b-input-group>
    </b-form-group>


    <!--<b-input-group style="width: 200px; float: left">
      <b-input-group-prepend is-text>
        Max tokens
      </b-input-group-prepend>

      <b-form-input type="number" v-model="generationParams.max_tokens"/>
    </b-input-group>-->
    <b-form-group>
      <b-input-group style="width: 300px; float: left">
        <b-input-group-prepend is-text>
          Model
        </b-input-group-prepend>
        <b-form-select
            v-model="generationParams.model"
            :options="modelsList.map(x => x.id)"
            class="mb-2 mr-sm-2 mb-sm-0"
        />
      </b-input-group>

      <b-input-group style="width: 200px; float: left">
        <b-input-group-prepend is-text>
          Temperature
        </b-input-group-prepend>

        <b-form-input
            v-model="generationParams.temperature"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
        />
      </b-input-group>

      <b-input-group style="width: 150px; float: left">
        <b-input-group-prepend is-text>
          Top_p
        </b-input-group-prepend>

        <b-form-input
            v-model="generationParams.top_p"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
        />
      </b-input-group>

      <b-input-group style="width: 250px; float: left">
        <b-input-group-prepend is-text>
          frequency_penalty
        </b-input-group-prepend>

        <b-form-input
            v-model="generationParams.frequency_penalty"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
        />
      </b-input-group>
      <b-input-group style="width: 250px; float: left">
        <b-input-group-prepend is-text>
          presence_penalty
        </b-input-group-prepend>

        <b-form-input
            v-model="generationParams.presence_penalty"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="number"
        />
      </b-input-group>
    </b-form-group>
  </b-tab>
</template>

<style scoped>

</style>