<template>
  <div>
    <b-btn class="ml-4" variant="primary" size="sm" @click="copy" v-if="mkb.selected.length > 0"
           title="Скопировать выделенные коды МКБ-10">
      <font-awesome-icon icon="copy"/>
      Скопировать
    </b-btn>
    <b-btn variant="primary" size="sm" @click="find" v-if="mkb.selected.length > 0" title="Найти документы">
      <font-awesome-icon icon="search"/>
      Найти документы
    </b-btn>
    <span v-if="mkb.selected.length > 0">Выбрано: {{ mkb.selected.length }}</span>
    <MKB-level/>
  </div>
</template>

<script>
import MKBLevel from './MKBTreeLevel.vue';
import MKB from '.';

export default {
  name: 'MKBTree',
  components: { MKBLevel },
  data: function () {
    return {
      parent: null,
      mkb: MKB
    }
  },
  methods: {
    copy() {
      this.copyToClipboard(this.mkb.selected)
    },
    find() {
      let codes = '';
      for (let i = 0; i < this.mkb.codes.length; i++) {
        codes += this.mkb.codes[i] + ' ';
      }
      let href = window.location.href.split('\/')[0];
      let url = `${ href }/docs?mkb=${ codes }`;
      window.open(url, '_blank');
    },
    copyToClipboard(val) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.setAttribute("id", "dummy_id");
      for (let i = 0; i < val.length; i++) {
        document.getElementById("dummy_id").value = document.getElementById("dummy_id").value + val[i] + '\n';
      }
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }
  },
  created() {
    const mkbId = this.$route.query.mkbId
    if (!mkbId) return
    this.parent = mkbId
    MKB.set_tree(this, mkbId)
  }
}
</script>

<style scoped>
</style>