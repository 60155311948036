<script>
import api from '@/api'
import CardMixin from '@/mixins/CardMixin'

export default {
  mixins: [CardMixin],
  props: {
    chunk: Object,
    section: Object
  },
  data() {
    return {
      formData: {
        text: '',
        text2: ''
      }
    }
  },
  methods: {
    onShow() {
      this.formData.text = this.chunk.text
      this.formData.text2 = ''
    },
    async apiCall() {
      const chunkIndex = this.section.chunks.indexOf(this.chunk)
      // Создаем новый чанк
      return api.post(`/corpus/section/${ this.chunk.section_id }/chunk`, {
        kind: 'divided',
        text: this.formData.text2,
        anchor: this.chunk.anchor
      }, {
        params: {
          after: this.chunk.id
        }
      })
          .then(async ({ data }) => {

            this.section.chunks.splice(chunkIndex + 1, 0, data)

            this.$bvToast.toast(
                'Создан новый чанк', { ...this.commonToaster, variant: 'success' }
            )

            // меняем текст в старом
            await api.post(`/corpus/chunk/${ this.chunk.id }`, {
              kind: 'divided',
              text: this.formData.text
            })
                .then(({ data }) => {
                  this.chunk.text = data.text
                  this.chunk.kind = data.kind
                  this.$bvToast.toast(
                      'Текст в текущем чанке изменен', { ...this.commonToaster, variant: 'success' }
                  )
                  this.$bvModal.hide('modal-divide-chunk')
                  this.$parent.eraseSelection()
                })
                .catch(error => {
                  console.log(error)
                  this.$bvToast.toast('Ошибка при изменении текста в старом чанке', {
                    ...this.commonToaster, variant: 'danger'
                  })
                })
          })
          .catch(error => {
            console.log(error)
            this.$bvToast.toast('Ошибка при создании нового чанка', {
              ...this.commonToaster, variant: 'danger'
            })
          })
    },
    clear() {
      this.formData.text2 = this.formData.text = ''
    }
  },
  computed: {
    stateText() {
      return this.formData.text.length >= 10
    },
    stateText2() {
      return this.formData.text2.length >= 10
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-divide-chunk"
      :title="`Разделение чанка '${(chunk ? chunk.title : undefined) || 'Без заголовка'}'`"
      lazy static centered scrollable ok-only no-close-on-backdrop
      size="lg"
      ok-title="Сохранить"
      @ok.prevent="save"
      @close="clear"
      @show="onShow"
      :busy="!(stateText && stateText2) || loading"
  >
    <label for="modal-text-formData">Текст текущего чанка</label>
    <b-textarea id="modal-text-formData" trim v-model="formData.text" rows="7" :state="stateText"/>
    <label for="modal-link-formData">Текст нового чанка</label>
    <b-textarea id="modal-text-formData" trim v-model="formData.text2" rows="7" :state="stateText2"/>

  </b-modal>
</template>

<style scoped>

</style>