export default {
  computed: {
    corpusId () {
      return Number(this.$route.params.corpusId)
    }
  },
  mounted () {
  },
  methods: {
    markup (text) {
      // Отображение ссылки
      text = text.replace(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/,
        (match) => `<a href="${ match }" target="_blank">${ match }</a>`
      )

      // Надстрочные
      text = text.replace(
        /\^(.+?)\^/g,
        (match, superscript) => `<sup>${ superscript }</sup>`
      )

      // Подстрочные
      text = text.replace(
        /~(.+?)~/g,
        (match, subscript) => `<sub>${ subscript }</sub>`
      )

      return text
    },
    calcChunkLink (chunk, source) {
      let anchor = `#${ chunk.anchor }`
      if (source.type === 'book') {
        anchor = `?anchor=${ chunk.anchor }${ anchor }`
      }
      return `${ process.env.VUE_APP_DOCUMENTS_ROOT }/${ source.document_id }/${ anchor }`
    }

  }
}