<template>
  <div class="mesh">
    <mesh-level/>
  </div>
</template>

<script>
import MeshLevel from './MeshTreeLevel.vue';

export default {
  name: 'MeshTree',
  components: { MeshLevel },
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.mesh {
  padding-top: 1em;
}
</style>