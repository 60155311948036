<script>
import api from '@/api'
import CardMixin from '@/mixins/CardMixin'

export default {
  mixins: [CardMixin],
  props: {
    chunks: Array,
    section: Object
  },
  data() {
    return {
      formData: {
        text: ''
      }
    }
  },
  methods: {
    onShow() {
      this.formData.text = this.chunksSorted.map(x => x.text.strip(' .')).join('. ') + '.'
    },
    async apiCall() {
      return api.post(`/corpus/chunk/join`, {
        editedChunk: {
          id: this.majorChunk.id,
          text: this.formData.text
        },
        deletedChunkId: this.deletedChunks.map(x => x.id)
      })
          .then(({ data }) => {

            this.majorChunk = data

            for (const chunk of this.deletedChunks) {
              this.section.chunks.splice(this.section.chunks.indexOf(chunk), 1)
            }

            this.$bvToast.toast(
                'Чанки объединены', { ...this.commonToaster, variant: 'success' }
            )

            this.$bvModal.hide('modal-join-chunks')
            this.$parent.eraseSelection()

          })
          .catch(error => {
            console.log(error)
            this.$bvToast.toast('Ошибка при объединении чанков', {
              ...this.commonToaster, variant: 'danger'
            })
          })
    },
    clear() {
      this.formData.text = ''
    }
  },
  computed: {
    chunksSorted() {
      return this.chunks.sort((a, b) => a.number - b.number)
    },
    majorChunk: {
      get() {
        return this.chunks.sort((a, b) => a.number - b.number)[0]
      },
      set(value) {
        const chunk = this.chunks.sort((a, b) => a.number - b.number)[0]
        Object.keys(value).forEach(key => {
          chunk[key] = value[key]
        })
      }
    },
    deletedChunks() {
      return this.chunksSorted.slice(1)
    },
    stateText() {
      return this.formData.text.length >= 10
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-join-chunks"
      :title="`Объединение чанков '${(majorChunk ? majorChunk.title : undefined) || 'Без заголовка'}'`"
      lazy static centered scrollable ok-only no-close-on-backdrop
      size="lg"
      ok-title="Сохранить"
      @ok.prevent="save"
      @close="clear"
      @show="onShow"
      :busy="!(stateText) || loading"
  >
    <label for="modal-text-formData">Текст чанка</label>
    <b-textarea id="modal-text-formData" trim v-model="formData.text" rows="14" :state="stateText" class="mb-3"/>
    <label for="modal-link-formData">Удаляемые чанки</label>
    <b-card bg-variant="light" v-for="chunk in deletedChunks" :key="`deleted-chunk-${chunk.id}`">
      {{ chunk.text }}
    </b-card>

  </b-modal>
</template>

<style scoped>

</style>