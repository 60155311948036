<template>
  <b-container fluid class="MKB">
    <b-row>

      <b-col class="col-10">
        <router-view/>
      </b-col>

      <b-col class="col-2">
        <b-nav pills vertical justified class="sidebar">
          <b-nav-item :to="{ name: 'MKBTree'}">Дерево</b-nav-item>
          <b-nav-item :to="{ name: 'MKBSearch'}">Поиск</b-nav-item>
        </b-nav>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'MKB',
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.MKB {
  padding-top: 1em;
}

.sidebar.nav-pills .nav-link.active,
.sidebar.nav-pills .show > .nav-link {
  background-color: #fff;
  color: rgba(0, 84, 166, 1);
  font-weight: 600;

}

.sidebar.nav-pills a.nav-link {
  color: rgba(0, 84, 166, 0.75)
}
</style>