const QUIZ = {
  rules: {
    common: {
      format: 'Результат нужно выдать в json формате, который будет содержать все тесты, которые ты напишешь. Точный json формат с примером теста я приведу ниже',
      answerFormat: 'Тест должен иметь четыре ответа, только один верный. Верный ответ нужно записать в поле "answer" в json формате. Неверные ответы (дистракторы) нужно записать в поле "distractors" в json формате',
      questionFormat: 'Условие теста нужно записать в поле "question". Условие теста должно представлять собой одну часть предложения, правильный ответ или дистрактор – вторую часть предложения. Между условием теста и ответами должно быть согласование по смыслу, в роде, числе и падеже',
      disease: 'Если в тексте упоминается определенная болезнь или пациенты с определенной болезнью, то эта категория пациентов и эта болезнь должны учитываться в условиях всех тестов, которые будут генерироваться из этого текста, но не в верных ответах этих тестов',
      condFormat: 'Условие теста не должно быть вопросом',
      nePart: 'В условии теста строго не должно использоваться отрицание - не должно быть частицы не',
      ansLength: 'Ответы теста строго не должны быть длиннее условия теста. Повторяющиеся части ответов теста нужно переносить в условие теста',
      ansVerno: 'Не должно быть слов Верно или Неверно в ответах теста',
      distrLength: 'Длина дистракторов не должна сильно отличаться от длины верного ответа. Все ответы одного теста должны быть приблизительно одной длины и степени подробности. Если длина верного ответа будет существенно отличаться от длины дистракторов, то получишь штраф',
      distrTheme: 'Каждый из дистракторов должен быть в рамках подраздела медицинской специальности, указанного ниже, в спецификации тестов. Подраздел медицинской специальности является также заголовком того текста, который будет тебе выдан как основа для генерации тестов. Поэтому при выборе дистракторов тебе нужно их искать в рамках этого подраздела медицинской специальности, как темы дистракторов.\n' +
        'Например, рассмотрим текст «При прогрессировании атеросклеротического процесса, вызванного дислипидемией (ДЛП), атеросклероз артерий нижних конечностей сопровождается клинической картиной перемежающей хромоты. При отсутствии соответствующего лечения заболевание прогрессирует и может закончиться развитием гангрены нижних конечностей.». Для этого текста был задан заголовок (подраздел медицинской специальности) – «Перемежающая хромота как проявление атеросклероза нижних конечностей при ДЛП». Для этого текста и этого заголовка был сгенерирован такой тест (А – верный ответ, Б, В, Г - дистракторы): \n' +
        'При отсутствии соответствующего лечения атеросклероз артерий нижних конечностей может закончиться развитием\n' +
        'А. гангрены нижних конечностей\n' +
        'Б. тромбоза коронарных артерий\n' +
        'В. инфаркта миокарда\n' +
        'Г. ишемического инсульта\n' +
        'Здесь дистракторы В, Г являются неправильными, т.к. они выходят за рамки заголовка текста (подраздела медицинской специальности), хотя и находятся в рамках медицинской специальности. Правильными дистракторами в данном случае будут:\n' +
        'В. варикозного расширения вен \n' +
        'Г. полиартроза нижних конечностей \n' +
        'Поэтому при выборе дистракторов обращай особое внимание на подраздел медицинской специальности. Если дистракторы будут за рамками подраздела медицинской специальности, то получишь штраф',
      distrNotCorrect: 'Необходимо учитывать, что дистрактор - это неверный, но правдоподобный ответ. Поэтому дистракторы должны быть максимально правдоподобны. Все дистракторы к каждому тесту должны быть одинаково привлекательными для испытуемых, не знающих правильного ответа. Неправдоподобные дистракторы не предлагать',
      distrPartCorrect: 'Очень важно, чтобы дистрактор не являлся правильным ответом или частично правильным ответом (частью правильного ответа). Например, «нефротический синдром», «гломерулонефрит» являются частью верного ответа «хроническая болезнь почек», или, например, если верный ответ = развитие сердечно-сосудистых осложнений, то нельзя предлагать дистрактор = инсульт, поскольку инсульт является сердечно-сосудистым осложнением. Если дистрактор или его часть будет правильным ответом - будешь получать большой штраф',
      answerGrouping: 'Верный ответ и дистракторы должны быть понятиями одного уровня группировки. Например, если верный ответ - конкретный лекарственный препарат (ЛП), то и дистракторы должны быть ЛП. Например, если верный ответ = эзетимиб (это ЛП), то дистракторы = антидепрессанты, анальгетики, антигистаминные средства - не подходят, поскольку это группы ЛП.  Аналогично, если верный ответ - группа ЛП, то и дистракторы должны быть группой ЛП',
      quizFocus: 'Необходимо учитывать при создании тестов их общую направленность, которая определяется: медицинской специальностью, разделом этой специальности и подразделом этой специальности.   Предложенные тобой тесты должны быть в рамках этой специальности, раздела этой специальности, подраздела этой специальности и строго на основе предложенного мной текста. При этом дистракторы в тестах ты можешь брать не только из предложенного текста',
      listRule: 'Если предложенный тебе текст представляет собой список (список может быть нумерованным или просто через точку с запятой, или даже через запятую), то тесты из этого списка нужно делать так: \n' +
        'каждый элемент списка должен стать правильным ответом в отдельном тесте, к которому нужно будет найти дистракторы; в условии теста нужно писать примерно такую фразу (каждый раз исходя из контекста): одним из факторов, влияющих на (какой то признак из контекста) является .... Вот пример обработки такого списка:\n' +
        'текст на входе: "Стойкое и длительное повышение артериального давления (АД) обусловлено изменением соотношения трех гемодинамических показателей: 1) повышением общего периферического сосудистого сопротивления (ОПСС); 2) увеличением сердечного выброса (минутного объема); 3) увеличением объема циркулирующей крови (ОЦК)."\n' +
        'Пример теста из этого текста (ответы здесь отмечены буквами А, Б, В, Г, правильный ответ – А, остальные дистракторы):\n' +
        '"Одним из факторов, влияющих на стойкое и длительное повышение артериального давления, является\n' +
        'А. увеличение сердечного выброса\n' +
        'Б. уменьшение сердечного выброса\n' +
        'В. снижение объема циркулирующей крови\n' +
        'Г. уменьшение общего периферического сосудистого сопротивления".\n' +
        'При этом нельзя использовать элементы списка как дистракторы к другому элементу списка, поскольку дистрактор не должен быть верным (частично верным) ответом',
      obviousAnswer: 'Тест не должен иметь очевидный ответ. Например, вот из такого текста:\n' +
        '"При первичном гиперальдостеронизме и симптоматической гипертонии жалобы обусловлены основным заболеванием и включают мышечную слабость, полиурию, полидипсию, запоры", был сгенерирован такой тест с очевидным ответом (верный ответ - А.):\n' +
        'При первичном гиперальдостеронизме и симптоматической гипертонии жалобы обусловлены\n' +
        'А. основным заболеванием\n' +
        'Б. побочными эффектами лекарств\n' +
        'В. психологическими факторами\n' +
        'Г. внешними условиями\n' +
        'Правильный тест из этого текста, например, такой:\n' +
        'При первичном гиперальдостеронизме и симптоматической гипертонии жалобы обусловлены основным заболеванием и включают\n' +
        'А. мышечную слабость\n' +
        'Б. боль в грудной клетке\n' +
        'В. головокружение\n' +
        'Г. одышку'
    },
    pair: {
      quizFocus: 'Необходимо учитывать при создании тестов их общую направленность, которая определяется: медицинской специальностью, разделом этой специальности, названием документа, из которого тебе будут предложены пары вопрос - ответ,  а также в рамках подраздела медицинской специальности. Предложенные тобой тесты должны быть в рамках этой специальности, раздела и подраздела этой специальности и строго на основе предложенных мной пар вопрос - ответ. При этом дистракторы в тестах ты можешь брать не только из предложенного текста'
    }
  }
}


export default {
  quizPrompt ({ allowNeRule, params, documentParams, text, fromParagraphData }) {
    const system = `
Тебе нужно принять роль врача по специальности ${ params.speciality } и одновременно тестолога - методиста.
`.trim()
    let rules = []

    let i = 1
    let rule
    for (const key in QUIZ.rules.common) {
      if (!allowNeRule && key === 'nePart') {
        continue
      }
      if (fromParagraphData === undefined) {
        rule = QUIZ.rules.common[key]
      }
      else {
        rule = QUIZ.rules.pair[key] || QUIZ.rules.common[key]
      }

      rules.push(`${ i }) ${ rule }.`)
      i++
    }

    rules = rules.join('\n')
    let base, base2
    if (fromParagraphData === undefined) {
      base = 'на основе текста, который я приведу ниже'
      base2 = 'Вот текст из этого документа, на основе которого нужно сделать тесты'
    }
    else {
      base = 'на основе нескольких пар вопрос - ответ, которые я приведу ниже'
      base2 = 'Вот пары вопрос - ответ, на основе которых нужно сделать тесты'
    }
    const user = `
Нужно написать тесты ${ base }. Требования к тестам следующие:
${ rules }

Далее я передаю тебе спецификацию для тестов и текст для генерации:
Вот название медицинской специальности: ${ params.speciality }.
Вот название раздела медицинской специальности: ${ params.discipline }.
Вот название подраздела медицинской специальности: ${ params.subDiscipline }.
Вот название документа: ${ documentParams.name }.
${ base2 }:

${ fromParagraphData === undefined ? text : fromParagraphData.questionAnswerText }

Результат выдавай только в json формате, без твоих комментариев. Вот пример такого теста в json формате: {
  "tests": [
    {
      "question": "Препаратами первой линии для снижения частоты и интенсивности приступов стенокардии являются",
      "answer": "бета-адреноблокаторы и антагонисты кальция",
      "distractors": [
        "антагонисты кальция и пролонгированные нитраты",
        "ивабрадин и антагонисты кальция",
        "антагонисты кальция и дигоксин"
      ],
    }
  ]
}
Подумай и приступай
`.trim()
    return { system, user }
  },
  qaPrompt ({ text }) {
    const system = ''
    const user = 'Для текста, который я приведу ниже, тебе нужно создать все возможные пары вопрос-ответ ' +
      'таким образом, чтобы вопрос точно отражал основную тему или информацию, ' +
      'изложенную в тексте, а ответ был конкретным и информативным. ' +
      'Результаты нужно записать в формате SQuAD. ' +
      'Запиши json файл в твоем ответе не в виде строки, а в виде стандартной разметки. ' +
      `Вот мой текст: "${ text }"`
    return { system, user }
  }
}