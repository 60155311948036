import CorpusWrapper from '@/pages/Corpus/Wrapper.vue'
import Root from '@/pages/Corpus/Root.vue'
import Corpus from '@/pages/Corpus/Corpus.vue'
import Sources from '@/pages/Corpus/Sources.vue'
import Chunks from '@/pages/Corpus/Chunks.vue'

export default [
  {
    path: '/corpus',
    component: CorpusWrapper,
    meta: { requiresAuth: true },
    children: [
      {
        name: Root.name,
        path: '',
        component: Root
      },
      {
        name: Corpus.name,
        path: ':corpusId',
        component: Corpus
      },
      {
        name: Sources.name,
        path: ':corpusId/sources',
        component: Sources
      },
      {
        name: Chunks.name,
        path: ':corpusId/chunks',
        component: Chunks
      }
    ]
  }
]