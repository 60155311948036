import api from '@/api'

export default {
  data () {
    return {
      documentLink: '',
      documentParams: {
        id: '',
        name: '',
        anchor: '',
        specList: []
      }
    }
  },
  methods: {
    async loadDocumentData (docId, anchor) {
      let inDoc = false
      if (!docId) {
        docId = this.documentParams.id
        anchor = this.documentParams.anchor
        inDoc = true
      }
      return api.get(`/doc/${ docId }/params`, {
        params: { anchor }
      }).then(
        ({ data }) => {
          if (inDoc) {
            this.documentParams.name = data.docName
            this.documentParams.specList = data.speciality
          }

          return data
        }
      ).catch((error) => {
        if (error.response.status === 404) {
          this.$bvToast.toast(`Документ не найден`, {
            ...this.commonToaster, variant: 'danger'
          })
        }
        else {
          this.$bvToast.toast(`Ошибка при загрузке данных документа`, {
            ...this.commonToaster, variant: 'danger'
          })
          console.log(error)
        }
        throw error
      })
    },
    async revealAbbrev (docId, text) {
      return api.post(`/doc/${ docId }/reveal-abbrev`, { text })
        .then(response => {
          return response.data.text
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$bvToast.toast(`Документ не найден`, {
              ...this.commonToaster, variant: 'danger'
            })
          }
          else {
            this.$bvToast.toast(`Ошибка при загрузке данных документа`, {
              ...this.commonToaster, variant: 'danger'
            })
            console.log(error)
          }
        })
    },
    parseLink (url) {
      const res = { url, anchor: '', docId: '' }
      if (!url) return res

      const el = document.createElement('a')

      el.href = url
      if (el.hash) {
        res.anchor = decodeURI(el.hash).slice(1)
      }
      let pathSplit = el.pathname.split('/')
      res.docId = pathSplit[3]

      if (pathSplit[pathSplit.length - 1] === 'index.html') {
        pathSplit.splice(-1)
      }

      res.url = decodeURI(`${ el.protocol }//${ el.host }${ pathSplit.join('/') }${ el.hash }`)

      if (!res.docId) {
        this.$bvToast.toast(
          'Ссылка должна вести на документ в библиотеке', {
            ...this.commonToaster, variant: 'danger'
          }
        )
      }
      return res
    }
  },
  watch: {
    documentLink (value) {
      if (!value) {
        this.documentParams = {
          id: '',
          anchor: '',
          name: '',
          specList: []
        }
      }

      const parsed = this.parseLink(value)
      this.documentParams.id = parsed.docId
      this.documentParams.anchor = parsed.anchor
    }
  }
}
