<script>

export default {
  props: {
    label: String,
    show: {type: Boolean, default: true},
    click: Function,
    icon: String,
    iconClass: String
  }
}

</script>

<template>
  <b-btn v-on="$listeners" v-bind="$attrs" v-if="show">
    <font-awesome-icon v-if="icon" :icon="icon" :class="iconClass" fixed-width/>
    {{ label }}
  </b-btn>
</template>

<style scoped>

</style>