import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue'

import { Spinner } from 'spin.js'
import App from './App.vue'
import router from './router'

import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import './css/style.css'
import './css/colors.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import VueYandexMetrika from 'vue-yandex-metrika'
import api from '@/api'

Vue.config.productionTip = false

Vue.use(BootstrapVue)

library.add(fas, far, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const spinner = new Spinner({
    lines: 8, // The number of lines to draw
    length: 0, // The length of each line
    width: 30, // The line thickness
    radius: 35, // The radius of the inner circle
    scale: 1.25, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: '#0054a6', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: 'none', // Box-shadow for the lines
    position: 'absolute' // Element positioning
  }
)

Vue.mixin({
  computed: {
    commonToaster () {
      return {
        solid: true,
        noCloseButton: true,
        headerClass: 'hidden'
      }
    },
    modalOptions () {
      return {
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        hideHeaderClose: false,
        centered: true
      }
    }
  },
  methods: {
    SPINNER_SHOW () {
      const target = document.getElementById('spinner')
      spinner.spin(target)
    },
    SPINNER_HIDE () {
      spinner.stop()
    },
    formatDate (value, format) {
      if (!value) return
      switch (format) {
        case 'textDateTime':
          return new Date(value).toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
          })
        case 'date':
          return new Date(value).toLocaleDateString()
        default:
          return new Date(value).toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
          })
      }
    },
    async loadSpecList () {
      return api.get(process.env.VUE_APP_SPECIALITIES_LIST_API)
        .then(response => {
          const res = [];
          for (const item of response.data) {
            res.push(
              {
                code: item.code,
                text: `${ item.name.replace('\[Устар. замените\] ', '\[Устаревшая\] ') } ${ item.code }`,
                name: item.name
              })
          }

          res.sort((a, b) => {
            let fa = a.name.toLowerCase(), fb = b.name.toLowerCase()

            if (fa < fb) {
              return -1
            }
            if (fa > fb) {
              return 1
            }
            return 0
          });

          return res
        })
    },
    swapElements (array, index1, index2) {
      [array[index1], array[index2]] = [array[index2], array[index1]]
    }
  }
})

function getCookie (name) {
  const matches = document.cookie.match(new RegExp(
    "(?:^| )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^]*)"
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

Vue.prototype.getCookie = getCookie

if (!String.prototype.strip) {
  String.prototype.strip = function (chars) {
    if (!chars) {
      chars = ' '
    }
    let start = 0, end = this.length;

    while (start < end && chars.indexOf(this[start]) >= 0)
      ++start;

    while (end > start && chars.indexOf(this[end - 1]) >= 0)
      --end;

    return (start > 0 || end < this.length) ? this.substring(start, end) : this;
  }
}

// Аутентификация
router.beforeEach((to, from, next) => {
  const sessionId = getCookie('session_id')
  let root = to.matched[0]
  if (sessionId) {
    store.dispatch('LOAD_USER', { sessionId }).then((isLoggedIn) => {
      if (!isLoggedIn) {
        store.dispatch('LOGOUT')
        if (root.meta.requiresAuth) {
          next({ name: 'Login', query: { redirect: to.fullPath } })
        }
      }
      else {
        next()
      }
    })
  }

  else if (root.meta.requiresAuth) {
    next({ name: 'Login', query: { redirect: to.fullPath } })
  }
  else {
    next()
  }

})

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YA_METRIKA_ID,
  router: router,
  env: process.env.NODE_ENV,

  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true
})

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
