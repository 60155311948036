<template>
  <div class="mesh-descriptor">
    <h2>{{ info.name }}</h2>
    <b-tabs>
      <b-tab title="Информация">

        <b-table stacked :items="[info]" :fields="infoFields" class="table-borderless">
          <template slot="eng_name" slot-scope="data">
            <a :href="`https://meshb.nlm.nih.gov/record/ui?ui=${info.id}`" target="_blank">
              {{ data.value }}
            </a>
          </template>
          <template slot="entries" slot-scope="data">
            <div v-for="entry in data.value">
              {{ entry }}
            </div>
          </template>
          <template slot="refs" slot-scope="data">
            <div v-for="ref in data.value">
              <router-link v-if="ref.ref" :to="{ name: 'MeshDescriptor', params: { descriptorId: ref.ref }}">
                {{ ref.name }}
              </router-link>
              <template v-else>
                {{ ref.name }}
              </template>
            </div>
          </template>
        </b-table>


      </b-tab>
      <b-tab title="Модификаторы">

        <div v-for="entry in info.qualifiers">
          {{ entry }}
        </div>

      </b-tab>
      <b-tab title="Положение в дереве" active>
        <template v-if="!loading && !error" v-for="(position, index) in info.tree_positions">
          <template v-if="index > 0">
            <hr/>
          </template>
          <mesh-ancestor :children="position"></mesh-ancestor>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import Mesh from '.'
  import MeshAncestor from './MeshAncestors.vue'

  export default {
    name: "MeshDescriptor",
    components: { MeshAncestor },
    data () {
      return {
        infoFields: [
          { key: 'name', label: 'Наименование' },
          { key: 'id', label: 'Идентификатор' },
          { key: 'note', label: 'Справка' },
          { key: 'entries', label: 'Иные наименования' },
          { key: 'refs', label: 'Связанное' },
          { key: 'eng_name', label: 'Название на параллельном языке' }
        ],

        loading: true,
        error: false,

        info: {}

      }
    },

    mounted: function () {
      let descriptorId = this.$route.params.descriptorId
      Mesh.get_info (this, descriptorId)
    }
  }
</script>

<style>
  .table-borderless td,
  .table-borderless th {
    border: 0;
  }
</style>