import axios from 'axios';
// import router from '@/router';
import Vue from 'vue'

const api = axios.create({
  baseURL: process.env.VUE_APP_FLASK_ADDRESS,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
})

api.interceptors.response.use(null, (error) => {
  let message
  if (error.code === 'ERR_NETWORK') {
    message = '<500> Неизвестная ошибка на сервере'
    error.response = {}
  }
  if ([401, 403].includes(error?.response?.status)) {
    message = 'У вас нет разрешений на это действие'
  }

  if (message) {
    error.response.handled = true
    const vm = new Vue()
    vm.$bvToast.toast(message, {
      variant: 'danger',
      solid: true,
      noCloseButton: true,
      headerClass: 'hidden'
    })
    // error.response  = {}
  }

  // let path = '/error'
  // switch (error.response.status) {
  //   case 401:
  //     path = { name: 'Login', query: { redirect: config.url } }
  //     break
  //   case 404:
  //     path = '/404'
  //     break
  //   case 403:
  //     path = '/403'
  //     break
  // }
  // router.push(path);
  return Promise.reject(error);
})

export default api;