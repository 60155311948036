import GeneratorMixin from '@/mixins/GeneratorMixin'

export default {
  mixins: [GeneratorMixin],
  methods: {
    callGenerate () {
      this.setPrompts()
      this.generate()
    }
  },
  watch: {
    'messages.assistant' (value) {
      if (value.length) {
        this.$emit('input', value);
      }
    }
  }
}