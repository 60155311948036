<script>
import SetPassword from '@/components/SetPassword.vue'
import { mapGetters } from 'vuex'
import api from '@/api'

export default {
  name: 'UserProfile',
  components: { SetPassword },
  data() {
    const cookie = this.getCookie("session_id").split('.')[0]
    return {
      auth: {
        alert: {
          show: false,
          text: '',
          variant: 'success'
        },
        expertise: {
          name: '',
          email: '',
          connected: false,
          url: 'https://expertise.mededtech.ru',
          cookie: `lib-exp-${ cookie }`
        },
        multicase: {
          name: '',
          email: '',
          connected: false,
          url: 'https://create-multicase.mededtech.ru',
          cookie: `lib-mcase-${ cookie }`
        }
      }
    }
  },
  computed: {
    ...mapGetters(['USER'])
  },
  mounted() {
    this.getExternalAuth()
  },
  methods: {
    clearCache() {
      localStorage.clear();
      location.reload()
    },
    connect(app) {
      api.post(`/auth/external-auth/${ app }?sesid=${ this.auth[app].cookie }`).then((response) => {
            if (response.status === 200) {
              this.auth.alert.show = true
              this.auth.alert.text = 'Аккаунт успешно привязан'
              this.auth.alert.variant = 'success'
            }
            else {
              this.auth.alert.show = true
              this.auth.alert.text = 'Что-то пошло не так, подробности в консоли'
              this.auth.alert.variant = 'error'
            }
            this.getExternalAuth()
          }
      ).catch((error) => {
            if (error.response.status === 404) {
              this.auth.alert.show = true
              this.auth.alert.text = `Авторизуйтесь во внешнем приложении, <b>обновите страницу</b> и повторите попытку позже<br>
                  <a href="${ this.auth[app].url }" target="_blank">Перейти во внешнее приложение</a>`
              this.auth.alert.variant = 'danger'
            }
          }
      )
    },
    disconnect(app) {
      api.post(`/auth/external-auth/${ app }/disconnect`).then(
          (response) => {
            if (response.status === 200) {
              this.auth.alert.show = true
              this.auth.alert.text = 'Аккаунт успешно отвязан'
              this.auth.alert.variant = 'success'

              this.auth[app].name = ''
              this.auth[app].email = ''
              this.auth[app].connected = false
            }
            else {
              this.auth.alert.show = true
              this.auth.alert.text = 'Что-то пошло не так, подробности в консоли'
              this.auth.alert.variant = 'error'
            }
          }
      )
      // window.open(this.EXPERTISE_URL, '_blank')
    },
    getExternalAuth() {
      api.get('/auth/external-auth').then(
          (response) => {
            for (const item in response.data) {
              this.auth[item].name = response.data[item].name
              this.auth[item].email = response.data[item].login
              this.auth[item].connected = true
            }
          }
      )
    }
  }
}
</script>

<template>
  <b-container fluid>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-card title="Профиль">
          <b-card-text>
            <b-input-group class="mb-3">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="user" fixed-width/>
              </b-input-group-prepend>
              <b-form-input
                  id="form-username"
                  :value="USER.fullname"
                  readonly
              />
            </b-input-group>
            <b-input-group class="mb-3">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="envelope" fixed-width/>
              </b-input-group-prepend>

              <b-form-input
                  id="form-email"
                  :value="USER.email"
                  readonly
              />
            </b-input-group>

            <set-password/>

          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-card title="Связанные аккаунты">
          <b-card-text>
            <b-alert
                v-model="auth.alert.show"
                dismissible
                :variant="auth.alert.variant"
                v-html="auth.alert.text"
            />
            <b-input-group>
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="list-ul" fixed-width/>
                Экспертиза
              </b-input-group-prepend>
              <b-form-input
                  readonly
                  :value="auth.expertise.connected ? `${auth.expertise.name} [${auth.expertise.email}]` : 'не авторизован'"
              />
              <b-input-group-append>
                <b-btn @click="connect('expertise')" v-if="!auth.expertise.connected">
                  <font-awesome-icon icon="link"/>
                  Связать
                </b-btn>
                <b-btn @click="disconnect('expertise')" v-if="auth.expertise.connected">
                  <font-awesome-icon icon="unlink"/>
                  Отвязать
                </b-btn>
              </b-input-group-append>
              <img
                  style="display: none"
                  :src="`${this.auth.expertise.url}/mellophone/authentication.gif?sesid=${this.auth.expertise.cookie}`"
                  alt="expAuth"
              />
            </b-input-group>
            <b-input-group class="mt-3">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="notes-medical" fixed-width/>
                Авторская система
              </b-input-group-prepend>
              <b-form-input
                  readonly
                  :value="auth.multicase.connected ? `${auth.multicase.name} [${auth.multicase.email}]` : 'не авторизован'"
              />
              <b-input-group-append>
                <b-btn @click="connect('multicase')" v-if="!auth.multicase.connected">
                  <font-awesome-icon icon="link"/>
                  Связать
                </b-btn>
                <b-btn @click="disconnect('multicase')" v-if="auth.multicase.connected">
                  <font-awesome-icon icon="unlink"/>
                  Отвязать
                </b-btn>
              </b-input-group-append>
              <img
                  style="display: none"
                  :src="`${this.auth.multicase.url}/mellophone/authentication.gif?sesid=${this.auth.multicase.cookie}`"
                  alt="expAuth"
              />
            </b-input-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-card title="Разрешения">
          <b-card-text>
            <b-list-group>
              <b-list-group-item v-for="permission in USER.permissions" :key="permission.name">
                {{ permission.value }}
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-btn @click="clearCache" variant="secondary">
          Очистить кеш
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>
