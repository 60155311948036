<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import CardMixin from '@/mixins/CardMixin'
import api from '@/api'
import Generators from '@/pages/Corpus/Generators'

export default {
  props: {
    docId: String,
    selectedChunks: Array,
    corpus: Object,
    questionType: String,
    speciality: String
  },
  mixins: [DocumentMixin, CardMixin],
  data() {
    return {
      params: {
        speciality: '',
        discipline: '',
        subDiscipline: ''
      }
    }
  },
  methods: {
    onShow() {
      this.documentParams.id = this.docId
      this.params.speciality = this.speciality
      this.params.discipline = this.corpus.name
      this.loadDocumentData()
    },
    async apiCall() {
      const data = {}
      let func
      if (this.questionType === 'quiz') {
        func = (chunk) => Generators.quizPrompt({
          allowNeRule: true,
          params: { ...this.params, subDiscipline: chunk.title },
          documentParams: this.documentParams,
          text: chunk.text
        })
      }
      else {
        func = (chunk) => Generators.qaPrompt({ text: chunk.text })
      }

      this.selectedChunks.forEach(chunk => {
        data[chunk.id] = func(chunk)
      })

      api.post(`/generator/${this.questionType}/from-prompt`, data)
          .then(() => {
            this.$bvToast.toast(
                'Генерация запланирована', { ...this.commonToaster, variant: 'success' }
            )
            this.$bvModal.hide('modal-generator')
          })
          .catch(({ response }) => {
            console.log(response)
            this.$bvToast.toast('Какая-то ошибка', {
              ...this.commonToaster, variant: 'danger'
            })
          })


    },
    clear() {
      this.params.speciality = ''
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-generator"
      title="Параметры генерации"
      lazy static centered scrollable ok-only no-close-on-backdrop
      size="lg"
      ok-title="Генерировать"
      @ok.prevent="save"
      @close="clear"
      @show="onShow"
  >
    <template v-if="questionType === 'quiz'">
      <b-input-group class="mb-3">
        <b-input-group-prepend is-text>
          Специальность
        </b-input-group-prepend>

        <b-form-select v-model="params.speciality" :options="documentParams.specList" value-field="name"
                       text-field="name" :state="params.speciality > '' ? null : false"/>
      </b-input-group>
      <b-input-group class="mb-3">
        <b-input-group-prepend is-text>
          Дисциплина
        </b-input-group-prepend>

        <b-form-input v-model="params.discipline" trim :state="params.discipline > '' ? null : false"/>
      </b-input-group>

      <b-input-group class="mb-3">
        <b-input-group-prepend is-text>
          Тема дистракторов
        </b-input-group-prepend>

        <b-form-input value="Будет взята из заголовка чанка" readonly/>
      </b-input-group>
    </template>
    <template v-else>
      Запуск генерации для выбранных чанков
    </template>

  </b-modal>

</template>

<style scoped>

</style>