<script>

import { saveAs } from 'file-saver'
import api from '@/api'
import LBtn from '@/components/LBtn.vue'

export default {
  components: { LBtn },
  props: {
    tasks: Array
  },
  data() {
    return {
      expandedRows: {},
      fields: [
        { key: 'date_start', label: 'Запланировано', sortable: true, formatter: this.formatDate },
        { key: 'date_finish', label: 'Завершено', sortable: true, formatter: this.formatDate },
        { key: 'user.fullname', label: 'Пользователь', sortable: true },
        { key: 'description', label: 'Описание', sortable: false },
        { key: 'status_name', label: 'Статус', sortable: true },
        { key: 'msg', label: 'Сообщение', sortable: false }
      ]
    }
  },
  computed: {
    completedTasks() {
      return this.tasks.filter(x => x.status === 'complete')
    },
    activeTasks() {
      return this.computedTasks.filter(x => x.status !== 'complete')
    },
    computedTasks() {
      return this.tasks.map((task) => ({
        ...task,
        _showDetails: this.expandedRows[task.id]
      }))
    },
    finishedFields() {
      return [...this.fields.slice(0, 4), { key: 'result', label: 'Результат', sortable: false }]
    }
  },
  methods: {
    showMsg(rowId) {
      this.$set(this.expandedRows, rowId, !this.expandedRows[rowId]);
    },
    requeueTask(task) {
      api.post(`/task/${ task.id }/requeue`).then(() => {
        task.status = 'queue'
        task.status_name = 'В очереди'
        this.$bvToast.toast('Задача перезапущена', { variant: 'info', ...this.commonToaster })
      })
    },
    downloadResult(taskId, fileName) {
      saveAs(`${api.defaults.baseURL}/task/${ taskId }/result`, fileName)

    }
  }
}
</script>

<template>
  <b-modal
      id="modal-bg-tasks"
      title="Фоновые задачи"
      lazy static centered scrollable hide-footer
      size="xl"
      cancel-title="Закрыть"
  >
    <b-tabs>
      <b-tab :title="`Активные (${activeTasks.length})`">
        <b-table :items="activeTasks" :fields="fields" striped responsive sort-by="date_start" sort-desc>

          <template #cell(msg)="row">
            <b-button size="sm" @click="showMsg(row.item.id)" class="mr-2" v-if="row.item.msg > ''">
              {{ expandedRows[row.item.id] ? 'Скрыть' : 'Показать' }}
            </b-button>
          </template>

          <template #cell(status_name)="{value, item}">
            {{ value }}
            <b-btn variant="info" size="sm" @click="requeueTask(item)" v-if="item.status === 'fail'">
              Перезапустить
            </b-btn>
          </template>

          <template #row-details="row">
            <b-card>
              <pre><code>{{ row.item.msg }}</code></pre>
              <template #footer>
                <b-button size="sm" @click="showMsg(row.item.id)" class="mr-2">
                  {{ expandedRows[row.item.id] ? 'Скрыть' : 'Показать' }}
                </b-button>
              </template>
            </b-card>
          </template>
        </b-table>
      </b-tab>
      <b-tab :title="`Завершенные (${completedTasks.length})`" :active="activeTasks.length === 0">
        <b-table :items="completedTasks"
                 :fields="finishedFields"
                 striped
                 sort-by="date_start" sort-desc
                 responsive>
          <template #cell(result)="row">
            <l-btn v-if="row.item.has_result" icon="download" variant="primary" @click="downloadResult(row.item.id, row.item.description)"/>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<style scoped>
</style>