<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group id="documents-search-input">

        <span class="pl-2 pb-3 mb-1">
          <b-button style="white-space: normal; text-align: left; color: black; display: contents;" size="sm"
                    variant="link" @click="filter_spec_set">
            <span v-if="!spec_filter">
              <b class="text-muted">Фильтр по специальности </b>
            </span>
            <span v-if="spec_filter">
              <b class="text-muted">Скрыть фильтр</b>
            </span>
          </b-button>
          <span v-if="selectedSpec.length > 0">
            <br/>
            <b class="text-muted pl-2 pb-3 mb-1">Выбрано специальностей: </b> {{ selectedSpec.length }}
            <b-button size="sm" variant="link" @click="filter_spec_reset" v-if="selectedSpec.length > 0"
                      title="Сбросить фильтр">
              <font-awesome-icon icon="xmark"/>
            </b-button>
            <b-button size="sm" variant="link" @click="filter_spec_apply" v-if="spec_filter"
                      title="Применить">
              Применить
            </b-button>
          </span>
          <b-form-group v-if="spec_filter">
            <template slot="label">
              <b-form-checkbox v-model="allSelectedSpec"
                               :indeterminate="indeterminateSpec"
                               @change="toggleAllSpec"
              >
                {{
                  allSelectedSpec ? 'Снять выделение со специальностей' : 'Выбрать все специальности'
                }}
              </b-form-checkbox>
            </template>
            <span id="scroll">
              <b-form-checkbox-group stacked
                                     v-model="selectedSpec"
                                     :options="spec_array"
                                     text-field="text"
                                     value-field="code"
                                     class="ml-4"
              ></b-form-checkbox-group>
            </span>
          </b-form-group>

        </span>

        <b-input-group>
          <b-form-input v-model="search_string"
                        :state="state_search"
                        placeholder="Поиск в документах"
                        @change="reset_page">
          </b-form-input>
          <b-input-group-append>
            <span v-on:click="search_reset(true)" class="search-reset">
              <font-awesome-icon icon="xmark"/>
            </span>
            <b-btn variant="primary" type="submit">
              Найти
            </b-btn>
          </b-input-group-append>
        </b-input-group>

        <span class="pl-2">
          <b-button style="white-space: normal; text-align: left; color: black; display: contents;" size="sm"
                    variant="link" @click="filter_set">
            <span v-if="!text_filter">
              <b class="text-muted">Расширенный поиск</b>
            </span>
            <span v-if="text_filter">
              <b class="text-muted">Параметры поиска: </b>{{ text_filter }}
            </span>
          </b-button>

          <b-button size="sm" variant="link" @click="filter_reset" v-if="text_filter" title="Сбросить фильтр">
            <font-awesome-icon icon="xmark"/>
          </b-button>
        </span>

        <div v-if="flag_filter" style="border-style: groove;" class="p-1">
          <b-form-group>
            <template slot="label">
              <b-form-checkbox v-model="allSelected"
                               :indeterminate="indeterminate"
                               @change="toggleAll"
              >
                {{ allSelected ? 'Снять выделение со всех категорий' : 'Выбрать все категории' }}
              </b-form-checkbox>
            </template>
            <b-form-checkbox-group stacked
                                   v-model="selected"
                                   :options="classifications"
                                   text-field="name"
                                   value-field="id"
                                   class="ml-4"
            ></b-form-checkbox-group>
          </b-form-group>

          <b-form-group
              id="fieldset"
              horizontal
              :label-cols="2"
              label="Проф. сообщество:">
            <b-input-group>
              <b-form-input v-model="search_string_prof_assoc"
                            placeholder="Поиск по профессиональному сообществу"
                            @change="reset_page">
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
              id="fieldset"
              horizontal
              :label-cols="2"
              label="Коды МКБ-10:">
            <b-input-group>
              <b-form-input v-model="search_string_mkb"
                            placeholder="Поиск по кодам МКБ-10"
                            @change="reset_page">
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
              id="fieldset"
              horizontal
              :label-cols="2"
              label="Год документа:">
            <b-input-group>
              <b-form-input v-model="search_string_data"
                            placeholder="Поиск по году документа"
                            @change="reset_page">
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
              id="fieldset"
              horizontal
              :label-cols="1"
              label="Заголовок:">
            <b-input-group>
              <b-form-input v-model="search_string_name"
                            placeholder="Поиск в заголовке документа"
                            @change="reset_page">
              </b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
              id="fieldset"
              horizontal
              :label-cols="1"
              label="Документ:">
            <b-input-group>
              <b-form-input v-model="search_string_doc_id"
                            placeholder="Введите идентификатор документа"
                            @change="reset_page">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </div>


        <div class="float-right">
          <b-badge class="found-result-count" variant="light" v-if="search_flag && found_count === 0">Нет
            результатов
          </b-badge>
          <b-badge class="found-result-count" variant="light" v-if="search_flag && found_count !== 0">
            Результат: {{ found_count }} документов.
          </b-badge>
        </div>
      </b-form-group>
    </b-form>

    <div class="float-right" style="margin-top: -32px" v-if="search_flag && found_count !== 0">
      Сортировка:
      <b-form-select v-model="selected_sort" :options="options_sort" @change="change_sort"
                     style="width: auto; border: none; vertical-align: unset; padding: 0 1.5rem 0 0 !important; height: auto">
      </b-form-select>
    </div>
    <div>

      <div v-for="(found_item, found_index) in found_items.hits">
        <b-card no-body>
          <b-card-header>
            <div>
              <div style=" display:inline; float: right;">
                <b-badge
                    :class="found_item._source.doc_class">
                  {{
                    classifications[classifications.findIndex(k => k.id ===
                        found_item._source.doc_class)].shortname | uppercase
                  }}
                </b-badge>
              </div>
              <b-link target="_blank"
                      v-bind:href="`${DOC_ROOT}/${found_item._source.doc_id}`">
                <h6 style="display:inline;">
                  {{
                    ((found_index + 1) + (20 * (current_page - 1)) + '. ' +
                        found_item._source.doc_title)
                  }}
                </h6>
              </b-link>
            </div>
            <b-button size="sm" variant="link"
                      @click="doc_set(found_item._source.doc_id)">
              Искать в этом документе
            </b-button>


            <b-btn class="more_link"
                   v-b-toggle="found_item._source.doc_id + 'doc_atrr'"
                   variant="link"
                   size="sm"
                   v-if="found_item._source.mkb || found_item._source.prof_assoc || found_item._source.speciality">

              <span class="when-opened">Скрыть подробности</span>
              <span class="when-closed">Подробнее</span>
            </b-btn>

            <b-row style="display:inline; float: right; padding-right: 14px">
              <div v-if="found_item._source.date">
                <b>Год документа: </b>
                {{ found_item._source.date }}
              </div>
            </b-row>

            <b-collapse :id="found_item._source.doc_id + 'doc_atrr'" class="m-2">
              <div v-if="found_item._source.date">
                <b>Год документа: </b>
                {{ found_item._source.date }}
              </div>
              <div v-if="found_item._source.mkb">
                <b>МКБ-10: </b>
                <div v-for="row in found_item._source.mkb" style="display: inline">
                  {{ row.id }}
                </div>
              </div>
              <div v-if="found_item._source.prof_assoc">
                <b style="display: block">Проф. сообщества: </b>

                <div v-for="row in found_item._source.prof_assoc">
                  {{ row.name }}
                </div>
              </div>
              <div v-if="found_item._source.speciality">
                <b style="display: block">Специальности: </b>

                <div v-for="row in found_item._source.speciality">
                  {{ row.name + ' ' + row.code }}
                </div>
              </div>
            </b-collapse>
          </b-card-header>
          <b-card-body v-if="found_item.inner_hits && found_item.inner_hits.data.hits.hits[0].highlight">
            <!--{{ found_item.max_score }}-->
            <div style="float: right; padding-right: 5px"> (Совпадений в документе:
              {{ found_item.inner_hits.data.hits.total }})
            </div>

            <div v-for="hit_item in found_item.inner_hits.data.hits.hits.slice(0, 5)">
              <div class="add-info" v-if="hit_item.highlight && found_item._source.doc_class !== 'book'">
                ...
                <b-link target="_blank"
                        v-bind:href="`${DOC_ROOT}/${found_item._source.doc_id}/index.html#${hit_item._source.id}`"
                        v-html="hit_item.highlight['data.html_text'][0]"></b-link>
                ...
              </div>
              <div class="add-info" v-if="hit_item.highlight && found_item._source.doc_class === 'book'">
                ...
                <b-link target="_blank"
                        v-bind:href="`${DOC_ROOT}/${found_item._source.doc_id}/?anchor=${hit_item._source.id}#${hit_item._source.id}`"
                        v-html="hit_item.highlight['data.html_text'][0]"></b-link>
                ...
              </div>
            </div>
            <div v-if="found_item.inner_hits.data.hits.hits.length > 5">
              <b-button size="sm" variant="link" @click="show_details.push(found_item._source.doc_id)"
                        v-if="show_details.indexOf(found_item._source.doc_id) === -1">
                Еще
              </b-button>
              <b-button size="sm" variant="link"
                        @click="show_details.splice(show_details.indexOf(found_item._source.doc_id), 1)"
                        v-if="show_details.indexOf(found_item._source.doc_id) !== -1">
                Скрыть
              </b-button>
            </div>
            <div v-if="show_details.indexOf(found_item._source.doc_id) !== -1"
                 v-for="hit_item in found_item.inner_hits.data.hits.hits.slice(5)">
              <div class="add-info" v-if="hit_item.highlight && found_item._source.doc_class !== 'book'">
                ...
                <b-link target="_blank"
                        v-bind:href="`${DOC_ROOT}/${found_item._source.doc_id}/index.html#${hit_item._source.id}`"
                        v-html="hit_item.highlight['data.html_text'][0]"></b-link>
                ...
              </div>
              <div class="add-info" v-if="hit_item.highlight && found_item._source.doc_class === 'book'">
                ...
                <b-link target="_blank"
                        v-bind:href="`${DOC_ROOT}/${found_item._source.doc_id}/?anchor=${hit_item._source.id}#${hit_item._source.id}`"
                        v-html="hit_item.highlight['data.html_text'][0]"></b-link>
                ...
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>

      <b-pagination v-if="found_count > 0"
                    align="right" size="sm" :total-rows="found_count" v-model="current_page" :per-page="per_page"
                    @input="change_page">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import Doc from '.'

export default {
  name: "DocSearch",
  props: {
    selected_class: String,
    classifications: Array,
    spec_array: Array
  },
  data () {
    return {
      selected: [],
      selected_sort: 'default',
      options_sort: [
        { value: 'default', text: 'По наилучшему совпадению' },
        { value: 'date', text: 'По году документа' }
      ],
      selectedSpec: [],
      allSelected: false,
      allSelectedSpec: false,
      flag_filter: false,
      indeterminate: false,
      indeterminateSpec: false,
      current_page: 1,
      per_page: 20,
      search_string: null,
      search_string_name: null,
      search_string_prof_assoc: null,
      search_string_mkb: null,
      search_string_doc_id: null,
      search_string_data: null,
      search_flag: false,
      text_filter: null,
      spec_filter: null,
      state_search: null,
      found_items: {
        buckets: []
      },
      found_count: null,
      show_details: []
    };
  },
  methods: {
    change_sort() {
      this.current_page = 1;
      Doc.search(this)
    },
    filter_spec_apply() {
      this.spec_filter = false;
      this.$emit('change_spec', this.selectedSpec);
      this.search_handler();
    },
    filter_spec_set() {
      this.spec_filter ^= true;
    },
    filter_set() {
      this.flag_filter ^= true;
      if (!this.flag_filter) {
        if (this.selected.length > 0) {
          this.text_filter = "Категорий: " + this.selected.length + ' ';
        }
        else {
          this.text_filter = ''
        }
        if (this.search_string_name) {
          this.text_filter += "Заголовок: " + this.search_string_name + ' '
        }
        if (this.search_string_doc_id) {
          this.text_filter += "Документ: " + this.search_string_doc_id + ' '
        }
        if (this.search_string_prof_assoc) {
          this.text_filter += "Проф. сообщество: " + this.search_string_prof_assoc + ' '
        }
        if (this.search_string_mkb) {
          this.text_filter += "МКБ-10: " + this.search_string_mkb + ' '
        }
        if (this.search_string_data) {
          this.text_filter += "Год документа: " + this.search_string_data + ' '
        }
      }
    },
    doc_set(doc_id) {
      let href = window.location.href.split('\/')[0];
      let url = `${ href }?search=${ this.search_string ? this.search_string : '' }&doc_id=${ doc_id }`;
      window.open(url, '_blank');
    },
    filter_reset() {
      this.selected = this.all_class_ids;
      this.text_filter = null;
      this.search_string_name = null;
      this.search_string_doc_id = null;
      this.search_string_prof_assoc = null;
      this.search_string_data = null;
      this.search_string_mkb = null;
      this.search_handler();
    },
    filter_spec_reset() {
      this.selectedSpec = [];
      this.spec_filter = null;
      this.search_reset();
      this.$emit('change_spec');
    },
    search_reset() {
      this.selected = this.all_class_ids;
      this.text_filter = null;
      this.selectedSpec = [];
      this.spec_filter = null;
      this.search_string_name = null;
      this.search_string_doc_id = null;
      this.search_string_prof_assoc = null;
      this.search_string_data = null;
      this.search_string_mkb = null;
      this.search_string = null;
      this.found_items = {
        buckets: []
      };
      this.search_flag = false;
      this.found_count = 0;
    },
    toggleAll(checked) {
      this.selected = checked ? this.all_class_ids : []
    },
    toggleAllSpec(checked) {
      this.selectedSpec = checked ? this.all_spec_ids : []
    },
    search_handler() {
      this.spec_filter = null;
      Doc.search(this)
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.search_handler();
    },
    reset_page() {
      this.current_page = 1;
    },
    change_page() {
      this.search_handler();
    },
    check_query() {
      let flag_query = false;
      if (this.$route.query.doc_id) {
        this.search_string_doc_id = this.$route.query.doc_id;
        flag_query = true;
        this.flag_filter = true;
        this.filter_set();
      }
      if (this.$route.query.prof_assoc) {
        this.search_string_prof_assoc = this.$route.query.prof_assoc;
      }
      if (this.$route.query.mkb) {
        this.search_string_mkb = this.$route.query.mkb;
        flag_query = true;
        this.flag_filter = true;
        this.filter_set();
      }
      if (this.$route.query.search) {
        this.search_string = this.$route.query.search;
        flag_query = true
      }
      if (flag_query) {
        this.search_handler();
      }
    }
  },
  mounted: function () {
//      Doc.get_doc_classification(this);
    this.check_query()
  },
  computed: {
    DOC_ROOT() {
      return `${process.env.VUE_APP_DOCUMENTS_ROOT}`
    },
    all_class_ids() {
      return this.classifications.map((a, _) => a['id'])
    },
    all_spec_ids() {
      return this.spec_array.map((a, _) => a['code'])
    }
  },
  filters: {
    uppercase: function (v) {
      return v.toUpperCase();
    }
  },
  watch: {
    selected_class(newVal) {
      if (newVal) {
        this.text_filter = null;
        this.search_string_name = null;
        this.search_string_doc_id = null;
        this.search_string_prof_assoc = this.$route.query.prof_assoc && newVal === 'clinrec_other' ? this.$route.query.prof_assoc : null;
        this.search_string_mkb = null;
        this.search_string = null;
        this.search_string_data = null;
        this.selected = [newVal];
        this.filter_set();
        this.search_handler();
      }
      else {
        this.search_reset()
      }
    },
    selected(newVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false
      }
      else if (newVal.length === this.all_class_ids.length) {
        this.indeterminate = false;
        this.allSelected = true
      }
      else {
        this.indeterminate = true;
        this.allSelected = false
      }
    },
    selected_spec(newVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminateSpec = false;
        this.allSelectedSpec = false
      }
      else if (newVal.length === this.all_spec_ids.length) {
        this.indeterminateSpec = false;
        this.allSelectedSpec = true
      }
      else {
        this.indeterminateSpec = true;
        this.allSelectedSpec = false
      }
    },
    found_items() {
      let searchInput = this.$el.querySelector("#documents-search-input");
      searchInput.scrollIntoView()
    }
  }
}
</script>

<style scoped>
.found-result-count {
  margin-bottom: 20px;
  font-size: 12px;
}

.card {
  margin-top: 10px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.more_link {
  text-decoration: underline;
  text-decoration-style: dotted;
  color: black;
}

.more_link:link {
  text-decoration: underline;
  text-decoration-style: dotted;
  color: black;
}

#scroll {
  display: block;
  height: 200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

</style>