<script>
import api from '@/api'
import DocumentMixin from '@/mixins/DocumentMixin'
import CorpusMixin from '@/mixins/CorpusMixin'
import StatCard from '@/components/StatCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CorpusEdit',
  components: { StatCard },
  mixins: [DocumentMixin, CorpusMixin],
  data() {
    return {
      /*stats: {
        source: { title: 'Источники', icon: 'book-open', color: 'var(--blue)', value: {} },
        section: { title: 'Разделы', icon: 'section', color: 'var(--purple)', value: {} },
        chunk: { title: 'Чанки', icon: 'align-left', color: 'var(--yellow)', value: {} },
        quiz: { title: 'Тесты', icon: 'list-check', color: 'var(--cyan)', value: {} },
        qa: { title: 'Вопросы', icon: 'circle-question', color: 'var(--green)', value: {} }
      }*/
      specList: [],
      stat: {
        items: [],
        fields: [
          { key: 'source_id', label: 'Id', sortable: true },
          { key: 'source_title', label: 'Источник', sortable: true },
          { key: 'nums.section', label: 'Разделов', sortable: true },
          { key: 'nums.chunk', label: 'Чанков', sortable: true },
          { key: 'nums.chunk_excluded', label: 'Исключенных', sortable: true },
          { key: 'nums.quiz', label: 'Тестов', sortable: true },
          { key: 'nums.quiz_done', label: 'Проверенных', sortable: true },
          { key: 'nums.question_answer', label: 'Вопросов', sortable: true },
          { key: 'nums.question_answer_done', label: 'Проверенных', sortable: true }

        ]
      }
    }
  },
  mounted() {
    this.loadCorpusById()
    this.loadStats()
    this.loadSpecList()
        .then(res => this.specList = res)
        .catch((error) => console.log(error))
  },
  watch: {
    corpusId() {
      this.loadCorpusById()
      this.loadStats()
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION']),
  },
  methods: {
    async loadStats() {
      api.get(`/corpus/${ this.corpusId }/stats`)
          .then(({ data }) => {
            /*Object.keys(data).forEach(key => {
              this.stats[key].value = data[key]
            })*/
            this.stat.items = data
          })
          .catch(({ response }) => {
            if (response.handled) return
            this.$bvToast.toast(
                'Неизвестная ошибка при загрузке статистики', {
                  ...this.commonToaster, variant: 'danger'
                }
            )
            console.log(response)
          })
    },
    callCorpusUpdate() {
      this.updateCorpus(this.corpus.data)
    },
    callDeleteCorpus() {
      this.deleteCorpus(this.corpus.data)
    }
  }
}
</script>

<template>
  <b-container fluid class="p-2">
    <b-row>
      <b-col cols="12" lg="6">
        <!--<b-card-group>-->
          <b-card title="Редактирование корпуса">
            <b-form-group>
              <b-input-group prepend="Название">
                <b-form-input
                    v-model="corpus.data.name"
                    trim
                    :disabled="!HAS_PERMISSION('corpus_edit')"
                    placeholder="Введите новое название корпуса"
                    @keydown.enter="callCorpusUpdate"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <b-input-group prepend="Специальность">
                <b-form-select v-model="corpus.data.speciality_data" :disabled="!HAS_PERMISSION('corpus_edit')">
                  <option v-for="speciality in specList" :value="{name: speciality.name, code: speciality.code}">
                    {{ speciality.text }}
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <template #footer>
              <small class="text-muted" v-if="corpus.data.last_modified">
                Последнее редактирование {{ formatDate(corpus.data.last_modified.date, 'textDateTime') }} ({{ corpus.data.last_modified.user.fullname }})
              </small>
              <b-btn-group v-if="HAS_PERMISSION('corpus_edit')" style="float: right">
                <b-btn @click="callCorpusUpdate"
                       :disabled="corpus.data===''"
                       :variant="corpus.data > '' ? 'success' : 'danger'">
                  <font-awesome-icon :icon="['far', 'floppy-disk']"/>
                  Сохранить
                </b-btn>
                <b-btn variant="danger" @click="callDeleteCorpus">
                  <font-awesome-icon icon="trash"/>
                  Удалить
                </b-btn>
              </b-btn-group>
            </template>
          </b-card>

        <!--</b-card-group>-->
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card title="Статистика">
          <b-table :items="stat.items" :fields="stat.fields" striped sort-by="source_id" stacked="lg">
            <template #custom-foot="data">
              <tr>
                <td v-for="field in stat.fields">
                  <b v-if="field.key.startsWith('nums.')">
                    {{
                      data.items.reduce((accumulator, currentValue) => accumulator + currentValue.nums[field.key.split('.')[1]], 0)
                    }}
                  </b>
                </td>
              </tr>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <!--<stat-card
          v-for="(stat, key) in stats"
          :key="key"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value.num"
          :color="stat.color"
          increase-interval="За последнюю неделю"
      />-->
    </b-row>
  </b-container>
</template>
