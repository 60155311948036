import { render, staticRenderFns } from "./EditSection.vue?vue&type=template&id=420e74af&scoped=true"
import script from "./EditSection.vue?vue&type=script&lang=js"
export * from "./EditSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "420e74af",
  null
  
)

export default component.exports