<script>
import Vue from 'vue'

import GeneratorCardMixin from '@/mixins/GeneratorCardMixin'
import DocumentMixin from '@/mixins/DocumentMixin'

import PromptTab from '@/components/PromptTab.vue'
import Rephrase from '@/components/Rephrase.vue'
import Table2Text from '@/components/Table2Text.vue'
import Header2Distractor from '@/components/Header2Distractor.vue'
import ModalEditQuest from '../modals/EditQuest.vue'
import Generators from '@/pages/Corpus/Generators/index'
import { mapGetters } from 'vuex'

export default {
  name: 'GeneratorQA',
  components: { Header2Distractor, Table2Text, Rephrase, PromptTab, ModalEditQuest },
  mixins: [GeneratorCardMixin, DocumentMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION']),
    calcTitle() {
      const t = ['Генератор вопросы-ответы']
      if (this.fromChunkData) {
        t.push(`для чанка "${ this.fromChunkData.chunkTitle }"`)
      }
      return t.join(' ')
    }
  },
  mounted() {
    this.generationParams.response_format = { "type": "json_object" }
    // _fixme убрать предзаполнение
    // this.documentLink = 'https://library.mededtech.ru/rest/documents/KP654/index.html#paragraph_9njhu1'
  },
  methods: {
    setPrompts() {
      const messages = Generators.qaPrompt({
        text: this.params.text
      })
      this.messages = { ...this.messages, ...messages }
      return this.messages
    },
    createQuestInChunk(quest) {
      if (!this.fromChunkData) {
        this.$bvToast.toast('Вы не в чанке', { ...this.commonToaster, variant: 'warning' })
      }

      Vue.set(this.edit, 'editQuest', { question: quest.question, answer: quest.answers[0].text })
      Vue.set(this.edit, 'chunk', { id: this.fromChunkData.chunkId, title: this.fromChunkData.chunkTitle })
      Vue.set(this.edit, 'questions', [])
      Vue.set(this.edit, 'questionType', 'question_answer')
      this.$bvModal.show('modal-edit-quest')
    }
  },
  watch: {}
}
</script>

<template>
  <b-container fluid>
    <modal-edit-quest :edit="edit"/>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-card :title="calcTitle">
          <b-card-text>
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-if="documentParams.name" block v-b-toggle.accordion-1 variant="secondary">
                  {{ documentParams.name }}
                </b-button>
                <template v-else>Ссылка на документ</template>
              </b-card-header>
              <b-collapse id="accordion-1" :visible="linkShow" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-input-group>
                    <b-input
                        v-model="documentLink"
                        placeholder="Вставьте ссылку на параграф в библиотеке в виде https://library.mededtech.ru/rest/documents/134_2020/#paragraph_ps0nr2"
                    />
                    <b-input-group-append>
                      <b-button variant="secondary" @click="documentLink = ''">
                        <font-awesome-icon icon="xmark"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-btn @click="clickLoadDataFromLink" icon variant="primary" :disabled="running || !documentLink">
                    <font-awesome-icon icon="share-from-square"/>
                    Загрузить данные из документа по ссылке
                  </b-btn>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-tabs content-class="mt-3" v-show="documentParams.name">
              <b-tab title="Параметры" active>

                <b-form-group>
                  <b-button-group>
                    <table2-text :text="params.text" @input="setText" class="mr-1 ml-1"/>
                    <rephrase :text="params.text" @input="setText" class="mr-1"/>
                    <b-btn @click="clickRevealAbbrev" variant="secondary">
                      Аббревиатуры
                    </b-btn>
                  </b-button-group>
                </b-form-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    Текст для генерации
                  </b-input-group-prepend>

                  <b-form-textarea v-model="params.text" trim rows="5" :state="params.text > '' ? null : false"/>

                </b-input-group>
              </b-tab>

              <prompt-tab :messages="messages" :generation-params="generationParams"/>
            </b-tabs>
          </b-card-text>
          <b-card-footer>
            <template v-if="documentParams.name && params.text > ''">
              <b-btn @click="generate()" icon variant="primary" :disabled="running">
                <font-awesome-icon icon="share-from-square"/>
                Генерировать
              </b-btn>

            </template>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-card>
          <b-card-text>
            <h4>
              Результат
              <stopwatch style="float: right;" ref="stopwatch"/>
            </h4>
            <template v-if="result.data">
              <h5>{{ result.data[0].title }}</h5>
              <b-card class="question mb-2"
                      v-for="(quest, questIndex) in result.data[0].paragraphs[0].qas"
                      :key="questIndex">
                <template #header>
                  <span>
                    {{ questIndex + 1 }}. {{ quest.question }}
                  </span>
                  <b-button-group size="sm"
                                  v-if="fromChunkData && fromChunkData.chunkId && HAS_PERMISSION('corpus_quest_edit')">
                    <b-btn @click="createQuestInChunk(quest)"
                           title="Скопировать вопрос в чанк" variant="outline-secondary">
                      <font-awesome-icon
                          fixed-width
                          icon="clone"
                      />
                    </b-btn>
                  </b-button-group>
                </template>
                <div class="ml-2">

                  <p v-for="answer in quest.answers">
                    {{ answer.text }}
                  </p>

                </div>
              </b-card>

            </template>

          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>

.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>