<script>
import GeneratorMixin from '@/mixins/GeneratorMixin'

export default {
  name: 'DistractorSearch',
  props: {
    theme: {
      type: String,
      default: ''
    }
  },
  mixins: [GeneratorMixin],
  data() {
    return {
      showDistractor: '',
      params: {
        text: '',
        theme: ''
      },
    }
  },
  methods: {
    setPrompts() {
      this.messages.user = `\
Я дам тебе короткую фразу. Тебе нужно подобрать все возможные антонимы к этой фразе и расположить их \
по убыванию похожести на эту фразу. Антонимы должны быть в рамках темы для антонимов, которую я тебе дам. \
Вот моя фраза: "${ this.params.text }". \
Вот тема для антонимов: "${ this.params.theme }". \
Подумай и приступай.`
    }
  },
  watch: {
    theme(value) {
      this.params.theme = value
    },
    params: {
      deep: true,
      handler() {
        this.setPrompts()
      }
    }
  }
}
</script>

<template>
  <b-container fluid style="padding: 0">
    <b-row no-gutters>
      <b-col>
        <h4>
          Поиск дистракторов
          <stopwatch style="float: right;" ref="stopwatch"/>
        </h4>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-2">
      <b-col class="pr-2" cols="6">
        <b-input-group>
          <b-input-group-prepend is-text>
            Тема
          </b-input-group-prepend>

          <b-form-input v-model="params.theme"/>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <b-input-group>
          <b-input-group-prepend is-text>
            Верный ответ
          </b-input-group-prepend>

          <b-form-input v-model="params.text"/>
          <b-input-group-append>
            <b-btn :disabled="params.text.trim() === ''" @click="generate">
              <font-awesome-icon icon="search"/>
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-textarea :value="messages.assistant" rows="5" max-rows="25"/>
    </b-row>

  </b-container>
</template>

<style scoped>

</style>