<template>
  <ul class="ml-4 p-0">
    <li v-for="item in nodes" class="item">
      <div>
        <template>
          <b-form-checkbox
              v-if="item.code"
              v-model="item.checkbox"
              :id="'form-checkbox-mkb' + item.code"
              class="checkbox-mkb"
              :value="get_item_label(item)"
              @input="check_mkb(get_item_label(item), item)"
          />
          <!--suppress EqualityComparisonWithCoercionJS -->
          <span v-if="item.actual == '1'" :id="item.code" v-html="get_item_label(item)"/>
          <!--suppress EqualityComparisonWithCoercionJS -->
          <s v-if="item.actual == '0'">
            <span :id="item.code" v-html="get_item_label(item)"/>
          </s>
          <span class="level-button" v-if="item.is_folder" @click="toggle(item)">
            <font-awesome-icon v-if="item.is_open" icon="minus-circle"/>
            <font-awesome-icon v-else icon="plus-circle"/>
          </span>
        </template>
      </div>
      <div v-if="item.is_open">
        <mkb-level :parent="item" :status="!item.checkbox"/>
      </div>
    </li>
  </ul>
</template>
<script>
import MKB from '.'

export default {
  name: 'mkb-level',
  props: {
    parent: Object,
    status: Boolean
  },
  data: function () {
    return {
      MKB: MKB,
      loading: true,
      error: false,
      nodes: []
    }
  },
  methods: {
    copy() {
      this.copyToClipboard(this.MKB.selected)
    },
    copyToClipboard(val) {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.setAttribute("id", "dummy_id");
      for (let i = 0; i < val.length; i++) {
        document.getElementById("dummy_id").value = document.getElementById("dummy_id").value + val[i] + '\n';
      }
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    toggle: function (node) {
      if (node.is_folder) {
        node.is_open = !node.is_open;
      }
    },
    check_mkb: function (value, item) {
      value = this.MKB.format_text(value);
      if (this.MKB.selected.indexOf(value) === -1) {
        if (!item.is_open) {
          this.toggle(item);
        }
        this.MKB.selected.push(value);
        this.MKB.codes.push(item.code)
      }
      else {
        this.MKB.selected.splice(this.MKB.selected.indexOf(value), 1);
        this.MKB.codes.splice(this.MKB.codes.indexOf(item.code), 1);
      }
    },
    get_item_label(item) {
      return this.MKB.get_item_label(item)
    }
  },
  created: function () {
    this.MKB.get_level(this)
  },
  watch: {
    status(val) {
      if (val) {
        for (let i = 0; i < this.nodes.length; i++) {
          this.nodes[i].checkbox = this.MKB.get_item_label(this.nodes[i])
        }
      }
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
}

.level-button {
  color: var(--primary);
  cursor: pointer;
  padding: 0;
}

.level-button svg {
  margin-bottom: -1px;
}

.checkbox-mkb {
  display: inline;
  padding: 0 0 0 0;
}

</style>