<script>
import api from '@/api'
import CorpusContentMixin from '@/mixins/CorpusContentMixin'

export default {
  name: 'DocumentAbbrevs',
  mixins: [CorpusContentMixin],
  props: {
    docId: {
      type: String,
      required: true
    },
    title: String,
    variant: {
      type: String,
      default: 'secondary'
    }
  },
  data() {
    return {
      abbrevs: []
    }
  },
  methods: {
    async getAbbrevs() {
      api.get(`/doc/${ this.docId }/abbrevs`).then(({ data }) => {
        this.abbrevs = data
      })
    }
  },
  beforeMount() {
    this.getAbbrevs()
  },
  watch: {
    docId() {
      this.getAbbrevs()
    }
  }
}
</script>

<template>
  <span>
    <b-btn v-b-toggle.sidebar-abbrev title="Показать аббревиатуры документа" :variant="variant">
      <font-awesome-icon icon="language"/>
      <template v-if="title">
        {{ title }}
      </template>
    </b-btn>

    <b-sidebar id="sidebar-abbrev" shadow="lg" right
               backdrop backdrop-variant="transparent"
               title="Аббревиатуры"
    >
      <table class="table b-table table-striped table-sm">
        <tbody>
        <tr v-for="item in abbrevs" :key="'abbrev-' + item.abbrev">
          <td v-html="markup(item.abbrev)"/>
          <td v-html="markup(item.translation)"/>
        </tr>
        </tbody>
      </table>
    </b-sidebar>
  </span>
</template>

<style>
#sidebar-abbrev {
  width: 400px
}
</style>