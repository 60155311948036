import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    user: {},
    corpusList: [],
    chunkIcons: {
      'list_item': {
        icon: 'list',
        tooltip: 'Элемент списка'
      },
      'paragraph': {
        icon: 'paragraph',
        tooltip: 'Параграф'
      },
      'joined': {
        icon: 'ellipsis',
        tooltip: 'Объединенные элементы списка'
      },
      'divided': {
        icon: 'diagram-project',
        tooltip: 'Разделенный чанк'
      },
      'table': {
        icon: 'table-cells',
        tooltip: 'Таблица'
      }
    }
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user

      if (user.session) {
        document.cookie = "session_id=" + user.session + ";Path=/;Secure;SameSite=Lax"
        api.defaults.headers['Authorization'] = `Bearer ${ user.session }`
        api.defaults.crossDomain = true
      }
      else {
        delete api.defaults.headers['Authorization']
        document.cookie = "session_id=;path=/;Max-Age=0"
      }
    },
    CORPUS_SET_LIST: (state, corpusList) => {
      state.corpusList = corpusList
    },
    CORPUS_ADD_TO_LIST: (state, item) => {
      state.corpusList.push(item)
    },
    CORPUS_REMOVE_FROM_LIST: (state, index) => {
      state.corpusList.splice(index, 1)
    }
  },
  actions: {
    LOAD_USER ({ commit }, { sessionId }) {
      const config = {}
      if (sessionId) {
        config.headers = {
          Authorization: `Bearer ${ sessionId }`
        }
        config.crossDomain = true
      }
      return api.get('/auth/me', config)
        .then((response) => {
          commit('SET_USER', response.data)
          return true
        })
        .catch(error => {
          console.log(error)
          return false
        })
    },
    LOGOUT ({ commit }) {
      commit('SET_USER', {})
    },
    CORPUS_LOAD_LIST({ commit }) {
      return api.get('/corpus')
        .then(({ data }) => commit('CORPUS_SET_LIST', data))
    }
  },
  getters: {
    USER (state) {
      return state.user
    },
    IS_LOGGED_IN (state) {
      return !!state.user.id
    },
    HAS_PERMISSION: state => (permission) => {
      return state.user?.permissions?.map(x => x.name).includes(permission)
    },
    CORPUS_LIST (state) {
      return state.corpusList
    },
    CHUNK_ICONS (state) {
      return state.chunkIcons
    }
  }

})

export default store