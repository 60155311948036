import api from '@/api'

export default {
  get_count_doc (context, spec = null) {
    if (spec) {
      api.get(`/search/count`, {
        params: {
          spec: spec
        }
      })
        .then(response => {
          context.info = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
    }
    else {
      api.get(`/search/count`)
        .then(response => {
          context.info = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  },
  get_doc_classification (context) {
    api.get(`/documents/classifications`)
      .then(response => {
        let selected = [];
        for (let i = 0; i < response.data.length; i++) {
          selected.push(response.data[i].id);
        }
        let classifications = response.data;
        context.classifications = classifications.sort((a, b) => {
          return a.sort > b.sort;
        });
        context.selected = selected
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  get_prof_assoc (context) {
    context.SPINNER_SHOW();
    api.get(`/search/prof_assoc?class_id=clinrec_other`)
      .then(response => {
        context.prof_assoc = response.data
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => (context.SPINNER_HIDE()));
  },
  search (context) {
    context.SPINNER_SHOW();
    context.filter_set();
    context.flag_filter = false;
    context.found_items = [];
    context.state_search = null;
    context.search_flag = true;

    api.get(`/search/search`, {
      params: {
        search_text: context.search_string,
        search_text_name: context.search_string_name,
        search_string_doc_id: context.search_string_doc_id,
        search_string_prof_assoc: context.search_string_prof_assoc,
        date: context.search_string_data,
        search_string_mkb: context.search_string_mkb,
        offset: (context.current_page - 1) * 20,
        type: context.selected,
        sort: context.selected_sort === 'default' ? null : context.selected_sort,
        spec: context.selectedSpec
      }
    })
      .then(response => {
        context.found_items = response.data.hits;
        context.found_count = response.data.hits.total;
        //context.doc_count = response.data.aggregations.total;

        context.$forceUpdate();
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => (context.SPINNER_HIDE()))
  }
}
