<script>
import api from '@/api'
import CardMixin from '@/mixins/CardMixin'
import { klona } from 'klona'

const defaultUserData = {
  id: '',
  email: '',
  fullname: '',
  permissions: []
}

export default {
  mixins: [CardMixin],
  emits: ['reload'],
  props: {
    edit: {
      type: Object
    },
    permissions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formData: klona(defaultUserData),
      permissionBlocks: [
        {
          name: 'Общие',
          check: x => !(x.startsWith('generators') || x.startsWith('corpus'))
        },
        {
          name: 'Генераторы',
          check: x => x.startsWith('generators')
        },
        {
          name: 'Корпусы',
          check: x => x.startsWith('corpus')
        }
      ]
    }
  },
  methods: {
    onShow() {
      if (this.edit.user) {
        this.formData.id = this.edit.user.id
        this.formData.email = this.edit.user.email
        this.formData.fullname = this.edit.user.fullname
        this.edit.user.permissions.forEach(x => {
          this.formData.permissions.push(x.name)
        })
      }
    },
    async apiCall() {

      let url = `/manage/users/`;
      if (this.edit.user) {
        url += `${ this.edit.user.id }/edit`
      }
      else {
        url += 'add'
      }

      return api.post(url, this.formData)
          .then(({ data }) => {
            if (!this.edit.user) {
              this.$bvToast.toast(
                  `Пользователь "${data.email}" успешно создан. Для возможности входа установите пароль)`,
                  { ...this.commonToaster, variant: 'success' }
              )
            }
            else {
              this.$bvToast.toast(
                  'Данные пользователя успешно изменены',
                  { ...this.commonToaster, variant: 'success' }
              )
            }
            this.$emit('reload')
            this.$bvModal.hide('modal-edit-user')
          })
          .catch(error => {
            if (error.response.status === 409) {
              switch (error.response.data) {
                case 'email_exists':
                  this.$bvToast.toast('Уже есть пользователь с таким email', {
                    ...this.commonToaster, variant: 'danger'
                  })
                  break
                case 'id_exists':
                  this.$bvToast.toast('Уже есть пользователь с таким id', {
                    ...this.commonToaster, variant: 'danger'
                  })
                  break
              }
            }
            else {
              let msg
              if (this.edit.user) {
                msg = `Неизвестная ошибка при редактировании пользователя`
              }
              else {
                msg = `Неизвестная ошибка при добавлении пользователя`
              }
              this.$bvToast.toast(msg, { ...this.commonToaster, variant: 'danger' })
              console.log(error.response)
            }
          })
    },
    clear() {
      this.formData = klona(defaultUserData)
    }
  },
  computed: {
    modalTitle() {
      if (this.edit.user) {
        return `Редактирование пользователя ${ this.edit.user.fullname }`
      }
      return 'Добавление пользователя'
    },
    stateEmail() {
      return /(.+)@(.+){2,}\.(.+){2,}/.test(this.formData.email)
    },
    stateFullname() {
      return this.formData.fullname.length >= 3
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-edit-user"
      :title="modalTitle"
      lazy static centered scrollable ok-only no-close-on-backdrop
      ok-title="Сохранить"
      @ok.prevent="save"
      @hide="clear"
      @show="onShow"
      :busy="!(stateEmail && stateFullname) || loading"
  >
    <b-form-group>
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="id-badge" fixed-width/>
        </b-input-group-prepend>
        <b-form-input
            placeholder="Опционально идентификатор"
            :disabled="!!edit.user"
            v-model.trim="formData.id"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group :state="stateEmail" invalid-feedback="Введите email">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="envelope" fixed-width/>
        </b-input-group-prepend>
        <b-form-input
            placeholder="Email"
            rows="3"
            v-model.trim="formData.email"
            :formatter="(value) => value.toLowerCase()"
            :state="stateEmail"
        />
      </b-input-group>
    </b-form-group>

    <b-form-group :state="stateFullname" invalid-feedback="Минимум 3 символа">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="user" fixed-width/>
        </b-input-group-prepend>
        <b-form-input
            placeholder="ФИО"
            v-model="formData.fullname"
            trim
            :state="stateFullname"
        />
      </b-input-group>
    </b-form-group>

    <h5>Разрешения:</h5>
    <b-form-checkbox-group v-model="formData.permissions" stacked>
      <!--<b-card-group>-->
        <b-card
            :sub-title="card.name"
            v-for="(card, index) in permissionBlocks"
            :key="`perm-${index}`"
        >
          <b-form-checkbox
              v-for="option in permissions"
              v-if="card.check(option.name)"
              :key="`perm-${option.name}`"
              :value="option.name"
          >
            {{ option.value }}
          </b-form-checkbox>
        </b-card>
      <!--</b-card-group>-->

    </b-form-checkbox-group>

  </b-modal>
</template>

<style scoped>

</style>