import api from '@/api'

function capitalize (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export default {
  // level: {
  //     nodes: {},
  //     parent: null
  // },

  get_item_label (item) {
    return `${ capitalize(item.name) } [${ item.current_position }]`
  },

  get_info (context, descriptorId) {
    api.get(`/mesh/${ descriptorId }/`)
      .then(response => {
        context.info = response.data
        return response.data
      })
      .catch(function (error) {
        console.log(error)
        context.error = true
      })
      .finally(() => (context.loading = false))
  },

  get_level (context) {
    api.get(`/mesh/`, {
      params: {
        parent: context.parent,
        children: context.children
      }
    })
      .then(response => {
        context.nodes = response.data;
      })
      .catch(error => {
        console.log(error)
        context.error = true
      })
      .finally(() => (context.loading = false))
  },

  get_current_position (children_position, parent) {
    if (parent) {
      let a = parent.split('.')
      let b = children_position.split('.')
      return b.slice(0, a.length + 1).join('.')
    }
    else
      return children_position.split('.')[0]
  },

  get_by_position (context, position) {
    api.get(`/mesh/position/${ position }`)
      .then(response => {
        context.info = response.data;
      })
      .catch(error => {
        console.log(error)
        context.error = true
      })
      .finally(() => (context.loading = false))
  },

  search (context, query, size, offset) {
    if (!query) {
      context.error = true
      return
    }
    context.loading = true
    api.get(`/mesh/search`, {
      params: {
        q: query,
        size: size,
        offset: offset
      }
    })
      .then(response => {
        context.hits = response.data.hits
        context.suggest = response.data.suggest
        context.took = response.data.took
        context.total = response.data.total
      })
      .catch(error => {
        console.log(error.response.data)
        context.error = true
      })
      .finally(() => (context.loading = false))
  }
}
