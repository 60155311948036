<script>
import GeneratorMixin from '@/mixins/GeneratorMixin'
import Vue from 'vue'
import api from '@/api'

export default {
  data() {
    return {
      processedNum: 0,
      parents: []
    }
  },
  mixins: [GeneratorMixin],
  props: {
    selectedChunks: Object,
    sections: Array,
    selectedChunksNum: Number
  },
  methods: {
    onShow() {
      this.processedNum = this.selectedChunksNum
    },
    setPrompts(paragraph) {
      // При этом тебе нужно учесть заголовки тех разделов книги, в которых находится этот абзац.
      // Вот заголовки разделов книги для этого абзаца: "${parents.map(x => x + '.').join(' ') }"
      this.messages.assistant = ''
      this.messages.user = `\
      Я дам тебе абзац из книги и тебе нужно придумать короткую фразу для заголовка этого абзаца.
      Не включай слово "Заголовок" в ответ.
      Вот этот текст: "${ paragraph }".
      Подумай и приступай.
      `
    },
    // getParentSections(sectionIndex) {
    //   const parents = [this.sections[sectionIndex]]
    //
    //   let i = sectionIndex
    //
    //   while (i > 0) {
    //     i--
    //     let section = this.sections[i]
    //     // у прародителя уровень меньше чем у текущего раздела, но не 0
    //     if (section.level === 0 || section.level >= parents[0].level) continue
    //
    //     parents.push(section)
    //     break
    //   }
    //   return parents.map(x => x.title)
    // },

    async onOk() {
      this.processedNum = 0
      for (const sectionIndex in this.selectedChunks) {
        // const parents = this.getParentSections(sectionIndex)

        for (const chunk of this.selectedChunks[sectionIndex]) {
          this.setPrompts(chunk.text)
          await this.generate()
              .then(title => {
                api.post(`/corpus/chunk/${ chunk.id }`, { title })
                    .then(() => {
                      Vue.set(chunk, 'title', title)
                    })
                    .catch(error => {
                      console.log(error)
                      this.$bvToast.toast(`Ошибка при генерации заголовка для чанка "${ chunk.text }"`, {
                        ...this.commonToaster, variant: 'danger'
                      })
                    })
              })
              .finally(() => this.processedNum++)
        }
      }
    }
  },
  computed: {
    busy() {
      return this.processedNum < this.selectedChunksNum
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-generate-headings"
      title="Генерация заголовков"
      lazy static centered scrollable ok-only no-close-on-backdrop
      ok-title="Генерировать"
      @ok.prevent="onOk"
      @show="onShow"
      :busy="busy"
  >
    <template v-for="(chunks, sectionIndex) in selectedChunks">
      <template v-if="chunks.length > 0">
        <b>Раздел: {{ sections[sectionIndex].title }}</b>
        <ul>
          <li v-for="chunk in chunks">
            {{ chunk.title || 'Заголовок отсутствует' }}
          </li>
        </ul>
      </template>
    </template>
  </b-modal>
</template>

<style scoped>

</style>