<script>
import api from '@/api'
import DocumentMixin from '@/mixins/DocumentMixin'
import CorpusMixin from '@/mixins/CorpusMixin'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CorpusRoot',
  data() {
    return {
      corpusList: {
        loading: false,
        items: []
      },
      specList: [],
      edit: {
        enabled: false,
        item: {
          name: '',
          year: 2020,
          speciality_data: {}
        }
      }
    }
  },
  mixins: [DocumentMixin, CorpusMixin],
  computed: {
    ...mapGetters(['CORPUS_LIST', 'HAS_PERMISSION']),
    itemValid() {
      return !this.edit.enabled || (this.edit.item.name > '')
    },
    tableFields() {
      const res = [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'year', label: 'Год', sortable: true },
        { key: 'speciality_data.name', label: 'Специальность', sortable: true },
        { key: 'last_modified.date', label: 'Последнее редактирование', sortable: true, formatter: this.formatDate }
      ]
      if (this.HAS_PERMISSION('corpus_edit')) {
        res.push({ key: 'actions', label: 'Действия', sortable: false })
      }
      return res
    }
  },
  methods: {
    clickItemEdit(item) {
      this.edit.enabled = true
      if (!item.id) {
        item = {
          name: '',
          year: 2020,
          speciality_data: {}
        }
        this.corpusList.items.push(item)
      }
      Vue.set(this.edit, 'item', item)
    },
    clearEdit() {
      this.edit.enabled = false
      if (!this.edit.item.id) {
        this.corpusList.items.splice(-1)
      }
      Vue.set(this.edit, 'item', { name: '' })
    },
    callDeleteCorpus(item) {
      this.deleteCorpus(item).then(() => {
        this.loadCorpusList()
      })
    },
    loadCorpusList() {
      this.corpusList.loading = true
      this.$store.dispatch('CORPUS_LOAD_LIST')
          .then(() => {
            this.corpusList.items = this.CORPUS_LIST
          })
          .catch(({ response }) => {
            if (response.status === 401) {
              return this.$router.push({ path: '/' })
            }
            this.$bvToast.toast(
                'Неизвестная ошибка при загрузке списка корпусов', {
                  ...this.commonToaster, variant: 'danger'
                }
            )
            console.log(response)
          })
          .finally(() => this.corpusList.loading = false)
    },
    callUpdateCorpus(item) {
      if (item.id !== this.edit.item.id) {
        this.$bvToast.toast(
            'Сбой в редактировании корпуса. Попробуйте обновить страницу',
            { variant: 'danger', ...this.commonToaster }
        )
      }
      if (this.edit.item.id) {
        this.updateCorpus(this.edit.item).then(() => this.clearEdit())
      }
      else {
        api.post('/corpus', this.edit.item)
            .then(({ data }) => {
              this.corpusList.items.splice(-1)
              this.corpusList.items.push(data)
            })
            .catch(({ response }) => {
              if (response.handled) return

              if (response.status === 423) {
                return this.$bvToast.toast(
                    'Корпус с таким именем уже существует',
                    { ...this.commonToaster, variant: 'danger' }
                )
              }

              this.$bvToast.toast(
                  'Неизвестная ошибка при создании корпуса',
                  { ...this.commonToaster, variant: 'danger' }
              )
              console.log(response)
            })
      }
    },
    goToCorpus(corpusId) {
      this.$router.push({ name: 'CorpusEdit', params: { corpusId } })
    }
  },
  mounted() {
    this.loadCorpusList()
    this.loadSpecList()
        .then(res => this.specList = res)
        .catch((error) => console.log(error))
  }
}
</script>

<template>
  <b-card title="Корпусы">
    <b-btn-toolbar class="mb-2">
      <b-button-group>
        <b-btn @click="clickItemEdit" variant="outline-primary" v-if="HAS_PERMISSION('corpus_edit')">
          <font-awesome-icon icon="plus"/>
          Создать новый
        </b-btn>
        <b-btn @click="loadCorpusList" variant="outline-primary">
          <font-awesome-icon icon="arrows-rotate"/>
          Обновить
        </b-btn>
      </b-button-group>
    </b-btn-toolbar>
    <b-table :items="corpusList.items" :fields="tableFields" striped sort-by="source_id"
             :busy="corpusList.loading" hover @row-dblclicked="(item) => goToCorpus(item.id)">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"/>
          <strong>Идёт загрузка...</strong>
        </div>
      </template>
      <template #cell(name)="{ item, value }">
        <b-form-input v-model="edit.item.name" trim v-if="edit.item.id === item.id" :state="edit.item.name > ''"
                      autofocus/>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template #cell(year)="{ item, value }">
        <b-form-input v-model="edit.item.year" trim v-if="edit.item.id === item.id"
                      :state="edit.item.year > 2000 && edit.item.year <= new Date().getFullYear()" type="number"/>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template #cell(speciality_data.name)="{ item }">
        <b-form-select
            v-model="edit.item.speciality_data"
            v-if="edit.item.id === item.id"
        >
          <option v-for="speciality in specList" :value="{name: speciality.name, code: speciality.code}">
            {{ speciality.text }}
          </option>
        </b-form-select>
        <template v-else-if="item.speciality_data.code">
          {{ item.speciality_data.name }} {{ item.speciality_data.code }}
        </template>
      </template>
      <template #cell(actions)="{item, index}">
        <b-button-group size="sm">
          <template v-if="edit.item.id !== item.id">
            <b-btn @click="clickItemEdit(item, index)" title="Редактировать корпус">
              <font-awesome-icon icon="pen" fixed-width/>
            </b-btn>
            <b-btn @click="callDeleteCorpus(item, index)" title="Удалить корпус" variant="danger">
              <font-awesome-icon icon="trash" fixed-width/>
            </b-btn>
          </template>
          <template v-else>
            <b-btn @click="callUpdateCorpus(item)"
                   :disabled="!itemValid"
                   :variant="itemValid ? 'success' : 'danger'">
              <font-awesome-icon :icon="['far', 'floppy-disk']" fixed-width/>
            </b-btn>
            <b-btn @click="clearEdit">
              <font-awesome-icon icon="times" fixed-width title="Отменить изменения"/>
            </b-btn>
          </template>

        </b-button-group>
      </template>
    </b-table>
  </b-card>
</template>

<style scoped>

</style>