<template>
  <div class="col-xxl-2 col-xl-3 col-lg-4 col-6">
    <div class="card card-stats mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">{{ title }}</h5>
            <span class="h2 font-weight-bold mb-0">{{ value }}</span>
          </div>
          <div class="col-auto" v-if="icon">
            <div class="icon icon-shape text-white rounded-circle shadow"
                 :style="`background-color: ${color || 'grey'}`">
              <!--<i class="fas fa-chart-pie"></i>-->
              <font-awesome-icon :icon="icon" size="lg" fixed-width/>
            </div>
          </div>
        </div>
        <p class="mt-3 mb-0 text-muted text-sm" v-if="this.increase !== undefined">
          <span class="mr-2" :class="increaseColor">
            <font-awesome-icon v-if="this.increase > 0" icon="arrow-up"/>
            <font-awesome-icon v-else-if="this.increase < 0" icon="arrow-down"/>
            {{ increase }}%
          </span>
          <span class="text-nowrap">{{ increaseInterval }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    title: String,
    value: [Number, String, Object],
    icon: String,
    color: String,
    increase: Number,
    increaseInterval: String
  },
  computed: {
    increaseColor() {
      if (this.increase < 0) {
        return 'text-danger'
      }
      else if (this.increase > 0) {
        return 'text-success'
      }
    }
  }
}
</script>

<style scoped>

h2,
h5,
.h2,
.h5 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: .5rem;
  color: #32325d;
}

h5,
.h5 {
  font-size: .8125rem;
}


@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  p,
  h2 {
    orphans: 3;
    widows: 3;
  }

  h2 {
    page-break-after: avoid;
  }

  body {
    min-width: 992px !important;
  }
}

figcaption,
main {
  display: block;
}

.icon {
  width: 3rem;
  height: 3rem;
}

.icon i {
  font-size: 2.25rem;
}

.icon-shape {
  display: inline-flex;
  padding: 12px;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1500px) {
  .col-xxl-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}


</style>

