export default {
  encode (data) {
    data = JSON.stringify(data)
    // data = window.btoa(encodeURIComponent(data))
    return data
  },
  decode (data) {
    // data = decodeURIComponent(window.atob(data))
    data = JSON.parse(data)
    return data
  }
}