<template>

  <div>

    <b-form class="search-form" @submit="onSubmit">
      <b-form-group id="MKB-search-input">
        <b-input-group>
          <b-form-input v-model="search_string"
                        :state="search_state"
                        placeholder="Поиск по справочнику МКБ-10">
          </b-form-input>
          <b-input-group-append>
            <span v-on:click="search_reset(true)" class="search-reset">
              <font-awesome-icon icon="xmark"/>
            </span>
            <b-btn variant="primary" type="submit">
              Найти
            </b-btn>
          </b-input-group-append>
          <b-form-invalid-feedback>
            Пустой поисковой запрос
          </b-form-invalid-feedback>
        </b-input-group>

      </b-form-group>

      <b-form-text class="float-right" v-if="took > 0">
        Получен{{ total | pluralize('', 'о', 'о') }} {{ total }} результат{{ total | pluralize('', 'а', 'ов') }} за {{
          took
        }} мс
      </b-form-text>


      <div v-if="suggest.length > 0 && search_flag" class="suggest">
        <span class="label">Возможно, вы имели в виду:</span>
        <span class="item" v-for="item in suggest" v-on:click="click_sugest(item.text)"
              v-html="item.highlighted"/>
      </div>
    </b-form>

    <div class="elastic-result">

      <div v-for="(hit, hit_index) in hits">
        <template v-if="hit_index > 0">
          <hr/>
        </template>
        <template>
          <h6 v-if="hit">
            <!--suppress EqualityComparisonWithCoercionJS -->
            <span v-if="hit._source.actual == '1'" :id="hit._source.code" v-html="get_item_label(hit._source)"/>
            <!--suppress EqualityComparisonWithCoercionJS -->
            <s v-if="hit._source.actual == '0'">
              <span :id="hit._source.code"
                    v-html="get_item_label(hit._source)"/>
            </s>
            <b-btn variant="link" v-b-tooltip.hover class="level-button"
                   title="Перейти к месту в структуре дерева"
                   target="_blank" :to="'/mkb/tree/?mkbId='+hit._source.id">
              <font-awesome-icon icon="arrow-circle-right"/>
            </b-btn>
          </h6>
        </template>
      </div>

      <b-pagination v-if="hits && hits.length > 0"
                    align="right" size="sm" :total-rows="total" v-model="current_page" :per-page="per_page"
                    @input="change_page"/>
    </div>

  </div>
</template>

<script>
import MKB from '.';

export default {
  name: 'MKBSearch',
  data: function () {
    return {
      search_string: null,
      search_state: null,
      search_flag: false,
      current_page: 1,
      per_page: 20,
      loading: false,
      error: false,
      total: 0,
      took: 0,
      hits: [],
      parent: null,
      mkb: MKB,
      suggest: []
    }
  },
  filters: {
    pluralize(num, one, two, five) {
      let n = Math.abs(num);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    }
  },
  methods: {
    click_sugest(text) {
      this.search_string = text;
      this.search_handler()
    },
    get_item_label(item) {
      return MKB.get_item_label(item)
    },
    search_reset(reset_string) {
      if (reset_string) {
        this.search_string = null
        this.search_flag = false
      }
      this.search_state = null
      this.total = 0
      this.hits = []
      this.took = 0
      this.error = false
      MKB.selected = []
    },
    change_page() {
      this.search_handler()
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.current_page = 1
      this.search_handler()
    },
    get_offset() {
      return this.per_page * (this.current_page - 1)
    },
    search_handler() {
      this.search_reset(false)

      this.search_flag = true
      MKB.search(this, this.search_string, this.per_page, this.get_offset())
    }
  },
  watch: {
    loading(newVal) {
      if (!newVal)
        this.SPINNER_HIDE()
      else
        this.SPINNER_SHOW()
    },
    error(newVal) {
      if (newVal) {
        this.search_state = false
      }
    }
  }
}
</script>

<style scoped>
.search-form {
  padding: .5rem 1rem;
}

.elastic-result {
  padding-left: 1em;
}

.level-button {
  color: var(--primary);
  cursor: pointer;
  padding: 0;
}
</style>