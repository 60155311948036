<script>
import api from '@/api'

export default {
  name: 'Login',
  data: function () {
    return {
      credentials: {
        username: '',
        password: ''
      },
      error_txt: ''
    }
  },
  methods: {
    login() {
      this.error_txt = ''

      const credentials = {
        username: this.credentials.username,
        password: this.credentials.password
      }
      api({
        method: 'post',
        url: `/auth/login`,
        data: credentials,
        crossDomain: true
      })
          .then(response => {
            if (response.data) {
              this.$store.commit('SET_USER', response.data)

              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
              }
              else {
                this.$router.push("/")
              }
            }
          })
          .catch((error) => {
            this.error_txt = 'Проверьте правильность ввода логина или пароля.';
            console.log(error);
          })

    }
  }

}
</script>

<template>
  <b-container fluid>
    <b-row class="align-items-center" style="height: 80vh" no-gutters>
      <b-col class="mx-auto my-auto" cols="12" xl="4" lg="6" md="8">
        <b-card class="card shadow border" title="Авторизация" sub-title="Библиотека аккредитации">

          <b-form @submit.prevent="login">
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <font-awesome-icon icon="user"/>
                </b-input-group-prepend>
                <b-form-input
                    placeholder="Введите логин"
                    v-model="credentials.username"
                    autofocus
                    :formatter="(value) => value.toLowerCase().trim()"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <font-awesome-icon icon="lock"/>
                </b-input-group-prepend>
                <b-form-input
                    placeholder="Введите пароль"
                    type="password"
                    v-model.trim="credentials.password"
                />
              </b-input-group>
            </b-form-group>
            <b-alert variant="danger" show v-if="error_txt > ''" dismissible>
              <h5>Ошибка</h5>
              {{ error_txt }}
            </b-alert>
            <b-form-group>
              <b-btn variant="primary" type="submit" block>Войти</b-btn>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
