<template>
  <b-container fluid class="methodical">
    <h3>Методические материалы для авторов и экспертов:</h3>

    <b-row>
      <b-col>
        <b-button variant="text" class="m-1">Инструкции
        </b-button>
        <b-card>
          <b-button variant="text" class="m-1">Для тестовых заданий:
          </b-button>
          <b-list-group>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Shablon_zadaniy_ispr.docx"
                        target="_blank">
                Шаблон для тестовых заданий_v22.1.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Primer_fayla_ispr.docx"
                        target="_blank">
                Пример файла тестовых заданий_v22.1.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Instrukciya_po_predostavleniyu_testovyh_zadanij_dlya_akkreditacii.html"
                        target="_blank">
                Инструкция по представлению тестовых заданий_v22.1
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Specialnosti_i_razdely_po_specialnostyam.xlsx"
                        target="_blank">
                Специальности и разделы по специальностям и должностям.xlsx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Instrukciya_eksperta_paketa.html"
                        target="_blank">
                Инструкция для эксперта пакета тестовых заданий_v22.1
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Instrukciya_Ekspert_banka.html"
                        target="_blank">
                Инструкция для эксперта банка тестовых заданий_v22.1
              </b-button>
            </b-list-group-item>
          </b-list-group>

          <hr/>
          <b-button variant="text" class="m-1">Для практических заданий:
          </b-button>
          <b-list-group>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Pasport_shablon_PSA_030919.pdf"
                        target="_blank">
                Шаблон паспорта экзаменационной станции.pdf
              </b-button>
            </b-list-group-item>
          </b-list-group>

          <hr/>
          <b-button variant="text" class="m-1">Для ситуационных заданий (кейсов):
          </b-button>
          <b-list-group>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/SHablon_MK_pustoj_dlya_klinicheskih_specialnoste.docx"
                        target="_blank">
                Шаблон МК пустой для клинических специальностей.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/SHablon_MK_pustoj_dlya_neklinicheskih_specialnostej.docx"
                        target="_blank">
                Шаблон МК пустой для неклинических специальностей.docx
              </b-button>
            </b-list-group-item>
            <b-list-group-item>
              <b-button variant="link" style="text-align: start !important;"
                        href="https://library.mededtech.ru/files/Primer_zapolneniya_shablona_SZ.docx"
                        target="_blank">
                Пример заполнения шаблона МК.docx
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

      <b-col>
        <b-button
            variant="text" class="m-1">Видеоуроки
        </b-button>

        <b-list-group>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'Ekspertiza_testovykh_zadaniy_v_banke'"
                      class="m-1">
              Экспертиза тестовых заданий в банке (для эксперта банка ТЗ)
            </b-button>
            <b-collapse id="Ekspertiza_testovykh_zadaniy_v_banke"
                        @show="Ekspertiza_testovykh_zadaniy_v_banke = true"
                        @hidden="Ekspertiza_testovykh_zadaniy_v_banke = false">
              <b-embed
                  v-if="Ekspertiza_testovykh_zadaniy_v_banke"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Ekspertiza_testovykh_zadaniy_v_banke.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'Ekspertiza_testovykh_zadaniy'"
                      class="m-1">
              Экспертиза тестовых заданий (для эксперта пакета ТЗ)
            </b-button>
            <b-collapse id="Ekspertiza_testovykh_zadaniy"
                        @show="Ekspertiza_testovykh_zadaniy = true"
                        @hidden="Ekspertiza_testovykh_zadaniy = false">
              <b-embed
                  v-if="Ekspertiza_testovykh_zadaniy"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Ekspertiza_testovykh_zadaniy.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'Videoinstrukcija_dlja_jekspertizy_multikejsov'"
                      class="m-1">
              Видеоинструкция для экспертизы мультикейсов
            </b-button>
            <b-collapse id="Videoinstrukcija_dlja_jekspertizy_multikejsov"
                        @show="Videoinstrukcija_dlja_jekspertizy_multikejsov = true"
                        @hidden="Videoinstrukcija_dlja_jekspertizy_multikejsov = false">
              <b-embed
                  v-if="Videoinstrukcija_dlja_jekspertizy_multikejsov"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Videoinstrukcija_dlja_jekspertizy_multikejsov.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'author_answer_issagg'"
                      class="m-1">
              Видеоинструкция для автора мультикейса
            </b-button>
            <b-collapse id="author_answer_issagg"
                        @show="author_answer_issagg = true"
                        @hidden="author_answer_issagg = false">
              <b-embed
                  v-if="author_answer_issagg"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/author_answer_issagg.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'Tochnyye_ssylki_na_otkrytyye_istochniki'" class="m-1">
              Точные ссылки на открытые источники
            </b-button>
            <b-collapse id="Tochnyye_ssylki_na_otkrytyye_istochniki"
                        @show="Tochnyye_ssylki_na_otkrytyye_istochniki = true"
                        @hidden="Tochnyye_ssylki_na_otkrytyye_istochniki = false">
              <b-embed
                  v-if="Tochnyye_ssylki_na_otkrytyye_istochniki"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Tochnyye_ssylki_na_otkrytyye_istochniki.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'Tochnyye_ssylki_na_zakrytyye_istochniki_full'"
                      class="m-1">
              Точные ссылки на закрытые источники
            </b-button>
            <b-collapse id="Tochnyye_ssylki_na_zakrytyye_istochniki_full"
                        @show="Tochnyye_ssylki_na_zakrytyye_istochniki_full = true"
                        @hidden="Tochnyye_ssylki_na_zakrytyye_istochniki_full = false">
              <b-embed
                  v-if="Tochnyye_ssylki_na_zakrytyye_istochniki_full"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/Tochnyye_ssylki_na_zakrytyye_istochniki_full.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
          <b-list-group-item>
            <b-button style="text-align: start !important;"
                      variant="link" v-b-toggle="'curator_features_issagg'"
                      class="m-1">
              Возможности агрегатора замечаний для куратора
            </b-button>
            <b-collapse id="curator_features_issagg"
                        @show="curator_features_issagg = true"
                        @hidden="curator_features_issagg = false">
              <b-embed
                  v-if="curator_features_issagg"
                  type="iframe"
                  aspect="16by9"
                  src="https://library.mededtech.ru/files/curator_features_issagg.mp4"
                  allowfullscreen
              ></b-embed>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>

        <b-button
            variant="text" class="m-1">Мобильное приложение РЭ
        </b-button>

        <b-list-group>
          <b-list-group-item>
            <b-button variant="link" style="text-align: start !important;"
                      href="https://library.mededtech.ru/files/MedEdTech_2.3.30_apkcombo.com.apk"
                      target="_blank">
              APK файл
            </b-button>
          </b-list-group-item>
          <b-list-group-item>
            <b-button variant="link" style="text-align: start !important;"
                      href="https://library.mededtech.ru/files/apk_instruction.docx"
                      target="_blank">
              Инструкция по установке APK файла
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

export default {
  name: "Methodical",
  data: function () {
    return {
      Tochnyye_ssylki_na_zakrytyye_istochniki_full: false,
      Tochnyye_ssylki_na_otkrytyye_istochniki: false,
      Ekspertiza_testovykh_zadaniy_v_banke: false,
      Ekspertiza_testovykh_zadaniy: false,
      Videoinstrukcija_dlja_jekspertizy_multikejsov: false,
      author_answer_issagg: false,
      curator_features_issagg: false,
    };
  },
  methods: {},
  mounted: function () {
  }
}
</script>

<style scoped>
.methodical {
  padding-top: 1em;
  padding-left: 2em;
}

.list-group-item {
  padding: 0.2rem 0.4rem;
}

.card-body {
  padding: 0.5rem;
}

.card {
  margin-bottom: 2rem;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
</style>
