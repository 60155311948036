import api from '@/api'
import Vue from 'vue'

export default {
  data () {
    return {
      corpus: {
        loading: false,
        data: {}
      }
    }
  },
  computed: {
    corpusId () {
      return Number(this.$route.params.corpusId)
    }
  },
  mounted () {
  },
  methods: {
    loadCorpusById (children) {
      if (!this.corpusId) {
        return
      }
      this.corpus.loading = true
      let config = {}
      if (children) {
        config = { params: { children: true, stats: true } }
      }
      return api.get(`/corpus/${ this.corpusId }`, config)
        .then(({ data }) => {
          this.corpus.data = data
        })
        .finally(() => this.corpus.loading = false)
        .catch(({ response }) => {
          if (response.status === 401) {
            return this.$router.push({ path: '/' })
          }
          this.$bvToast.toast(
            'Неизвестная ошибка при загрузке данных корпуса', {
              ...this.commonToaster, variant: 'danger'
            }
          )
          console.log(response)
        })
    },

    deleteCorpus (corpus) {
      return new Vue().$bvModal.msgBoxConfirm(`Удалить корпус текстов "${ corpus.name || 'Безымянный' }"?`, {
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        hideHeaderClose: false,
        centered: true
      })
        .then((confirm) => {
          if (!confirm) return
          return api.delete(`/corpus/${ corpus.id }`)
            .then(() => {
              this.$bvToast.toast('Корпус удален', {
                ...this.commonToaster, variant: 'success'
              })
            })
            .catch(({ response }) => {
              if (response.handled) return
              if (response.status === 404) {
                return this.$bvToast.toast(
                  'Корпус не найден', {
                    ...this.commonToaster, variant: 'danger'
                  }
                )
              }
              else if (response.status === 423) {
                return this.$bvToast.toast(
                  'Для удаления корпуса необходимо сначала удалить все источники', {
                    ...this.commonToaster, variant: 'danger'
                  }
                )
              }
              this.$bvToast.toast(
                'Неизвестная ошибка при удалении корпуса', {
                  ...this.commonToaster, variant: 'danger'
                }
              )
              console.log(response)
            })
        })
    },
    async updateCorpus (corpus) {
      return api.post(`/corpus/${ corpus.id }`, {
        name: corpus.name,
        speciality_data: corpus.speciality_data,
        year: corpus.year
      })
        .then(() => {
          this.$bvToast.toast('Корпус сохранен', {
            ...this.commonToaster, variant: 'success'
          })
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            // если документа нет, он ошибка в другом месте кидается
            this.$bvToast.toast(
              'Корпус не найден', {
                ...this.commonToaster, variant: 'danger'
              }
            )
            return
          }
          console.log(error)
        })
    }

  }
}