<template>
  <div>

    <!-- ФОРМА ПОИСКА -->
    <b-form class="search-form" @submit="onSubmit">
      <b-form-group id="mesh-search-input">
        <b-input-group>
          <b-form-input v-model="search_string"
                        :state="search_state"
                        placeholder="Поиск в MeSH">
          </b-form-input>
          <b-input-group-append>
            <span v-on:click="search_reset(true)" class="search-reset">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </span>
            <b-btn variant="primary" type="submit">
              Найти
            </b-btn>
          </b-input-group-append>
          <b-form-invalid-feedback>
            Пустой поисковой запрос
          </b-form-invalid-feedback>
        </b-input-group>

        <b-form-text class="float-right" v-if="show_res_num">
          <!--Получено {{ total }} результатов-->
          Получен{{ total | pluralize('', 'о', 'о') }} {{ total }} результат{{ total | pluralize('', 'а', 'ов') }} за {{
          took }} мс
        </b-form-text>

      </b-form-group>

      <div v-if="suggest.length > 0" class="suggest">
        <span class="label">Возможно, вы имели в виду:</span>
        <span class="item" v-for="item in suggest" v-on:click="click_sugest(item.text)" v-html="item.highlighted"></span>
      </div>


    </b-form>
    <!-- /ФОРМА ПОИСКА -->

    <div class="elastic-result">

      <div v-for="(hit, hit_index) in hits">
        <template v-if="hit_index > 0">
          <hr/>
        </template>
        <router-link target="_blank" :to="{ name: 'MeshDescriptor', params: { descriptorId: hit._source.id }}">
          <h6>
            {{ `${element_num(hit_index + 1)}. ${hit._source.name}` }}
          </h6>
        </router-link>


        <div class="descriptor-hit-info">
          <div v-for="highlight in hit.highlight">
            <div v-for="high_hit in highlight" v-html="high_hit"></div>
          </div>
        </div>
      </div>

      <b-pagination v-if="hits && hits.length > 0"
                    align="right" size="sm" :total-rows="total" v-model="current_page" :per-page="per_page"
                    @input="change_page">
      </b-pagination>
    </div>

  </div>
</template>

<script>
  import Mesh from '.'

  export default {
    name: "MeshSearch",
    data: function () {
      return {
        search_string: null,
        search_state: null,
        current_page: 1,
        per_page: 20,
        loading: false,
        error: false,
        total: 0,
        took: 0,
        show_res_num: false,
        hits: [],
        suggest: []
      }
    },
    filters: {
      pluralize(num, one, two, five) {
        let n = Math.abs(num);
        n %= 100;
        if (n >= 5 && n <= 20) {
          return five;
        }
        n %= 10;
        if (n === 1) {
          return one;
        }
        if (n >= 2 && n <= 4) {
          return two;
        }
        return five;
      }
    },
    methods: {
      search_reset(reset_string) {
        if (reset_string) {
          this.search_string = null
        }
        this.search_state = null
        this.total = 0
        this.hits = []
        this.took = 0
        this.error = false
      },
      change_page() {
        this.search_handler()
      },
      onSubmit(evt) {
        evt.preventDefault()
        this.current_page = 1
        this.search_handler()
      },
      get_offset() {
        return this.per_page * (this.current_page - 1)
      },
      element_num(index) {
        return this.get_offset() + index
      },
      click_sugest(text) {
        this.search_string = text
        this.search_handler()
      },
      search_handler() {
        this.search_reset(false)

        console.log(`search ${this.search_string}`)
        // document.getElementById ('count_doc').style = 'margin: 8%; margin-bottom: 1%; margin-top: 1%';
        Mesh.search(this, this.search_string, this.per_page, this.get_offset())
        this.show_res_num = true
      }
    },
    watch: {
      loading(newVal) {
        if (newVal === false)
          this.SPINNER_HIDE()
        else
          this.SPINNER_SHOW()
      },
      error(newVal) {
        if (newVal) {
          this.search_state = false
        }
      }
    }
  }
</script>

<style scoped>
  .search-form {
    padding: .5rem 1rem;
  }

  .descriptor-hit-info {
    padding-left: 2em;
  }
</style>