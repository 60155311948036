<template>
  <b-container fluid class="mesh">
    <b-row>

      <b-col class="col-10">
        <router-view></router-view>
      </b-col>

      <b-col class="col-2">
        <b-nav pills vertical justified class="sidebar">
          <b-nav-item :to="{ name: 'MeshTree'}">Дерево</b-nav-item>
          <b-nav-item :to="{ name: 'MeshSearch'}">Поиск</b-nav-item>
        </b-nav>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Mesh',
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.mesh {
  padding-top: 1em;
}

.sidebar.nav-pills .nav-link.active,
.sidebar.nav-pills .show > .nav-link {
  background-color: #fff;
  color: rgba(0, 84, 166, 1);
  font-weight: 600;

}

.sidebar.nav-pills a.nav-link {
  color: rgba(0, 84, 166, 0.75)
}
</style>