<template>
  <ul>
    <li class="item" v-if="info.id">
      <div>
        <b-link :to="{ name: 'MeshDescriptor', params: { descriptorId: info.id }}">
          {{ get_item_label(info) }}
        </b-link>
      </div>
      <template v-if="childrenPositions.length > 1
                      && childrenPositions.slice(0, childrenPositions.length -1).join('.') !== info.current_position">
        <mesh-ancestor :parent="info.current_position" :children="children"></mesh-ancestor>
      </template>
      <template v-else>
        <mesh-level :parent="info.current_position" :highlight="[children]"></mesh-level>
      </template>
    </li>
  </ul>
</template>
<script>
  import Mesh from '.'
  import MeshLevel from './MeshTreeLevel.vue';

  export default {
    name: 'mesh-ancestor',
    components: { MeshLevel },
    props: {
      children: String,
      parent: String
    },
    computed: {
      childrenPositions () {
        return this.children.split ('.')
      }
    },
    data: function () {
      return {
        Mesh: Mesh,
        loading: true,
        error: false,
        info: {}
      }
    },
    methods: {
      get_item_label (item) {
        return Mesh.get_item_label (item)
      }
    },
    mounted () {
      let current_position = Mesh.get_current_position (this.children, this.parent)
      Mesh.get_by_position (this, current_position)
    }
  }
</script>

<style scoped>
  ul {
    padding-left: 2em;
    list-style-type: none;
  }

</style>