<script>
import ButtonGeneratorMixin from '@/mixins/ButtonGeneratorMixin'

export default {
  name: 'Rephrase',
  mixins: [ButtonGeneratorMixin],
  props: {
    text: {
      type: String
    },
    variant: {
      type: String,
      default: 'secondary'
    }
  },
  methods: {
    setPrompts() {
      this.messages.user = `Я дам тебе текст, тебе нужно перефразировать этот текст в более логически связанный текст. \
Вот мой текст: "${ this.text }"`
    }
  }
}
</script>

<template>
  <b-btn :variant="variant" @click="callGenerate" :disabled="running">
    Парафраз
    <template>
      <stopwatch ref="stopwatch"/>
    </template>
  </b-btn>
</template>

<style scoped>

</style>