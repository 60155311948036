<script>
export default {
  props: {
    label: String,
    show: {type: Boolean, default: true},
    hint: String,
    children: Array,
    icon: String,
    iconClass: String
  }
}

</script>

<template>
  <b-dropdown v-bind="$attrs" v-b-tooltip="hint" v-if="show">
    <template #button-content>
      <font-awesome-icon :icon="icon" :class="iconClass" fixed-width/>
      {{ label }}
    </template>
    <b-dropdown-item-button
        v-for="(dropBtn, dropBtnIndex) in children"
        :key="`actionBtn-${dropBtnIndex}`"
        :disabled="dropBtn.disabled"
        @click="dropBtn.click"
    >
      <font-awesome-icon v-if="dropBtn.icon" :icon="dropBtn.icon" :class="dropBtn.iconClass" fixed-width/>
      {{ dropBtn.label }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<style scoped>

</style>