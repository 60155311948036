<script>
import CardMixin from '@/mixins/CardMixin'
import api from '@/api'
import Vue from 'vue'

export default {
  props: {
    edit: {
      type: Object,
      default: {
        editQuest: {},
        questionType: '',
        chunk: {},
        questions: []
      }
    }
  },
  mixins: [CardMixin],
  data() {
    return {
      formData: {
        question: '',
        answer: '',
        distractors: Array(3).fill('')
      },
      isNew: false
    }
  },
  computed: {
    quest() {
      return this.edit.editQuest
    },
    stateQuestion() {
      return this.formData.question > ''
    },
    stateAnswer() {
      return this.formData.answer > ''
    },
    cardTitle() {
      const t = []
      if (this.isNew) {
        t.push('Добавление нового вопроса в чанк')
      }
      else {
        t.push('Редактирование вопроса в чанке')
      }
      t.push(`"${ this.edit?.chunk?.title }"`)
      return t.join(' ')
    }
  },
  methods: {
    onShow() {
      this.isNew = !this.edit.editQuest.id;

      this.formData.question = this.quest.question
      this.formData.answer = this.quest.answer

      this.formData.distractors = []
      if (this.quest.distractors) {
        this.quest.distractors.forEach(dis => this.formData.distractors.push(dis))
      }
    },
    async apiCall() {
      if (!this.isNew)
        return api.post(`/corpus/question/${ this.edit.questionType }/${ this.quest.id }`, {
          ...this.formData
        })
            .then(({ data }) => {
              Vue.set(this.edit.editQuest, 'question', data.question)
              Vue.set(this.edit.editQuest, 'answer', data.answer)
              if (this.quest.id) {
                this.formData.question = this.quest.question
                this.formData.answer = this.quest.answer
                if (this.quest.distractors) {
                  Vue.set(this.edit.editQuest, 'distractors', [])
                  data.distractors.forEach(dis => this.edit.editQuest.distractors.push(dis))
                }
              }
              this.$bvToast.toast(
                  'Вопрос сохранен', { ...this.commonToaster, variant: 'success' }
              )
              this.$bvModal.hide('modal-edit-quest')
            })
            .catch(({ error }) => {
              console.error(error)
            })

      else {
        return api.post(`/corpus/chunk/${ this.edit.chunk.id }/${ this.edit.questionType }`, {
          ...this.formData
        })
            .then(({ data }) => {
              this.edit.questions.push(data)
              this.$bvToast.toast(
                  'Вопрос создан', { ...this.commonToaster, variant: 'success' }
              )
              this.$bvModal.hide('modal-edit-quest')
            })
            .catch(({ error }) => {
              console.error(error)
            })
      }
    },
    clear() {
      this.formData.question = this.formData.answer = ''
      this.formData.distractors = Array(3).fill('')
      delete this.edit.questionType
      delete this.edit.editQuest
      delete this.edit.chunk
      delete this.edit.questions
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-edit-quest"
      :title='cardTitle'
      lazy static centered scrollable ok-only no-close-on-backdrop
      size="lg"
      ok-title="Сохранить"
      @ok.prevent="save"
      @close="clear"
      @show="onShow"
      :busy="!(stateQuestion && stateAnswer) || loading"
  >

    <b-input-group class="mb-2" title="Вопрос">
      <b-input-group-prepend is-text>
        <font-awesome-icon fixed-width :icon="['far', 'circle-question']"/>
      </b-input-group-prepend>
      <b-form-textarea trim v-model="formData.question" :state="stateQuestion"/>
    </b-input-group>

    <div class="ml-4">
      <b-input-group title="Правильный ответ">
        <b-input-group-prepend is-text>
          <!--А-->
          <font-awesome-icon fixed-width :icon="['far', 'circle-check']"/>
        </b-input-group-prepend>
        <b-form-textarea trim v-model="formData.answer" :state="stateAnswer"/>
      </b-input-group>

      <template v-for="(dis, disIndex) in formData.distractors" v-if="edit.questionType === 'quiz'">
        <b-input-group class="mt-2" :key="`key-edit-distractor-${disIndex}`" title="Дистрактор">
          <b-input-group-prepend is-text>
            <p class="text-center" style="width: 20px">
              {{ ['Б', 'В', 'Г'][disIndex] }}
            </p>
          </b-input-group-prepend>
          <b-form-textarea trim v-model="formData.distractors[disIndex]" :state="dis > ''"/>
        </b-input-group>
      </template>
    </div>

  </b-modal>
</template>

<style>
</style>