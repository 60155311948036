import api from '@/api'

export default {
  selected: [], codes: [], check: [], get_item_label (item) {
    let name = '';
    if (item.code !== null) {
      name = '<span class="mkbcode badge badge-light">' + item.code;
      if (Number(item.addl_code) === 1) {
        name = name + '+ '
      }
      if (Number(item.addl_code) === 2) {
        name = name + '* '
      }
      name = name + '</span> '
    }
    name = name + item.name;
    return name
  },

  format_text (val) {
    val = val.replace("<span class=\"mkbcode badge badge-light\">", '')
    val = val.replace('</span>', '')
    val = val.replace('<b>', '')
    val = val.replace('</b>', '')
    return val
  },

  get_level (context) {
    api.get(`/mkb/`, {
      params: {
        parent: (context.parent) ? context.parent.id : null
      }
    })
      .then(response => {
        context.nodes = response.data;
        if (context.parent && context.parent.checkbox !== false) {
          for (let i = 0; i < context.nodes.length; i++) {
            let name = this.get_item_label(context.nodes[i]);
            context.nodes[i].checkbox = name;
            if (this.selected.indexOf(this.format_text(name)) === -1) {
              this.selected.push(this.format_text(name));
              this.codes.push(context.nodes[i].code)
            }
          }
        }
        if (window.Context && window.Context['cur_position']) {
          let cur_position = window.Context['cur_position'];
          for (let i = 0; i < cur_position.length; i++) {
            let indx = context.nodes.findIndex(k => k.id == cur_position[i]);
            if (indx !== -1) {
              context.nodes[indx].is_open = true;
              context.nodes[indx].name = '<b> ' + context.nodes[indx].name + ' </b>';
              const e = document.getElementById(context.nodes[indx].code);
              if (!!e && e.scrollIntoView) {
                e.scrollIntoView();
              }
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
        context.error = true
      })
      .finally(() => (context.loading = false))
  },

  search (context, query, size, offset) {
    if (!query) {
      context.error = true;
      return
    }
    context.loading = true;
    api.get(`/mkb/search`, {
      params: {
        q: query, size: size, offset: offset
      }
    })
      .then(response => {
        context.hits = response.data.hits;
        context.suggest = response.data.suggest;
        context.took = response.data.took;
        context.total = response.data.total;
      })
      .catch(error => {
        console.log(error.response.data);
        context.error = true;
      })
      .finally(() => (context.loading = false))
  },

  set_tree (context, id) {
    api.get(`/mkb/position`, {
      params: {
        position: id
      }
    })
      .then(response => {
        window.Context = {
          'cur_position': response.data
        }
      })
      .catch(error => {
        console.log(error.response.data);
        context.error = true;
      })

  }
}
