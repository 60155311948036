<!--suppress JSDeprecatedSymbols -->
<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import UrlMixin from '@/mixins/UrlMixin'
import GeneratorCardMixin from '@/mixins/GeneratorCardMixin'

import DistractorSearch from '@/components/DistractorSearch.vue'
import QuizSearch from '@/components/QuizSearch.vue'
import Rephrase from '@/components/Rephrase.vue'
import Table2Text from '@/components/Table2Text.vue'
import PromptTab from '@/components/PromptTab.vue'
import Header2Distractor from '@/components/Header2Distractor.vue'

import ModalEditQuest from '../modals/EditQuest.vue'
import Vue from 'vue'
import Generators from '@/pages/Corpus/Generators/index'
import { mapGetters } from 'vuex'

export default {
  name: 'GeneratorQuiz',
  components: { PromptTab, Table2Text, Rephrase, DistractorSearch, QuizSearch, Header2Distractor, ModalEditQuest },
  mixins: [GeneratorCardMixin, DocumentMixin],
  data() {
    return {
      allowNeRule: true,
      params: {
        text: '',
        speciality: '',
        discipline: '',
        subDiscipline: '',
        examples: ''
      },
      fromParagraphData: undefined
    }
  },
  mounted() {
    this.generationParams.response_format = { "type": "json_object" }

    // _FIXME убрать предзаполнение
    // this.documentLink = 'https://library.mededtech.ru/rest/documents/KP654/index.html#paragraph_9njhu1'
    // this.params.speciality = 'Пульмонология'
    // this.params.discipline = 'Внебольничная пневмония'
    // this.params.subDiscipline = 'Значение ранней диагностики вирусов при тяжелой внебольничной пневмонии'

    if (this.$route.query.qa) {
      this.fromParagraphData = UrlMixin.decode(this.$route.query.qa)
      this.fromUrlParams(this.fromParagraphData)
      this.fromParagraphData.questionAnswerText = this.fromParagraphData.questions.map(x => `Вопрос: ${ x.question }\nОтвет: ${ x.answer }`).join('\n\n')
      this.clickLoadDataFromLink()
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION'])
  },
  methods: {
    setPrompts() {
      const messages = Generators.quizPrompt({
        allowNeRule: this.allowNeRule,
        params: this.params,
        documentParams: this.documentParams,
        text: this.params.text,
        fromParagraphData: this.fromParagraphData
      })

      this.messages = { ...this.messages, ...messages }
      return this.messages
    },
    setDistractorTheme(value) {
      this.params.subDiscipline = value
    },

    calcTitle() {
      const t = ['Генератор тестов на основе']
      if (this.fromParagraphData) {
        t.push('пар вопрос-ответ')
      }
      else {
        t.push('текста')
      }
      if (this.fromChunkData) {
        t.push(`для чанка "${ this.fromChunkData.chunkTitle }"`)
      }
      return t.join(' ')
    },

    createQuestInChunk(quest) {
      Vue.set(this.edit, 'editQuest', quest)
      Vue.set(this.edit, 'chunk', { id: this.fromChunkData.chunkId, title: this.fromChunkData.chunkTitle })
      Vue.set(this.edit, 'questions', [])
      Vue.set(this.edit, 'questionType', 'quiz')
      this.$bvModal.show('modal-edit-quest')
    }
  },
  watch: {
    allowNeRule() {
      this.setPrompts()
    }
  }
}
</script>

<template>
  <b-container fluid>
    <modal-edit-quest :edit="edit"/>
    <b-row class="mt-3">
      <b-col cols="12" md="6">
        <b-card :title="calcTitle()">
          <b-card-text>
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button v-if="documentParams.name" block v-b-toggle.accordion-1 variant="secondary">
                  {{ documentParams.name }}
                </b-button>
                <template v-else>Ссылка на документ</template>
              </b-card-header>
              <b-collapse id="accordion-1" :visible="linkShow" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-input v-model="documentLink" :state="documentLink > ''"
                           trim lazy
                           placeholder="Вставьте ссылку на параграф в библиотеке в виде https://library.mededtech.ru/rest/documents/134_2020/#paragraph_ps0nr2"/>

                  <b-btn @click="clickLoadDataFromLink" icon variant="primary" :disabled="running">
                    <font-awesome-icon icon="share-from-square"/>
                    Загрузить данные из документа по ссылке
                  </b-btn>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-tabs content-class="mt-3" v-show="documentParams.name">
              <b-tab title="Параметры" active>
                <b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    Специальность
                  </b-input-group-prepend>

                  <b-form-select v-model="params.speciality" :options="documentParams.specList" value-field="name"
                                 text-field="name" :state="params.speciality > '' ? null : false"/>
                </b-input-group>
                <b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    Дисциплина
                  </b-input-group-prepend>

                  <b-form-input v-model="params.discipline" trim :state="params.discipline > '' ? null : false"/>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    Тема дистракторов
                  </b-input-group-prepend>

                  <b-form-input v-model="params.subDiscipline" trim
                                :state="params.subDiscipline > '' ? null : false"/>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    Температура
                  </b-input-group-prepend>

                  <b-form-input
                      v-model="generationParams.temperature"
                      type="range" min="0" max="2" step="0.05"
                  />

                  <b-input-group-append is-text id="tempRangeValue">
                    {{ generationParams.temperature }}
                  </b-input-group-append>

                </b-input-group>

                <!--<b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    <b-form-checkbox v-model="allowNeRule"/>
                  </b-input-group-prepend>
                  <b-form-input disabled value='Запрет "НЕ" в условии теста'></b-form-input>


                  <b-input-group-append v-if="fromParagraphData === undefined">
                    <table2-text :text="params.text" @input="setText" class="mr-1 ml-1"/>


                    &lt;!&ndash;  <rephrase :text="params.text" @input="updateInputText"/>&ndash;&gt;
                    <header2-distractor :text="params.text" @input="setDistractorTheme" class="mr-1"/>

                    <b-btn @click="clickRevealAbbrev" variant="secondary">
                      Сокращения
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>-->

                <b-form-group>
                  <b-button-group>
                    <table2-text :text="params.text" @input="setText" class="mr-1 ml-1"/>
                    <header2-distractor :text="params.text" @input="setDistractorTheme" class="mr-1"/>
                    <rephrase :text="params.text" @input="setText" class="mr-1"/>
                    <b-btn @click="clickRevealAbbrev" variant="secondary">
                      Аббревиатуры
                    </b-btn>
                  </b-button-group>
                </b-form-group>

                <b-input-group class="mb-3" v-if="fromParagraphData === undefined">
                  <b-input-group-prepend is-text>
                    Текст
                  </b-input-group-prepend>

                  <b-form-textarea v-model="params.text" trim rows="5" :state="params.text > '' ? null : false"/>

                </b-input-group>

                <b-input-group class="mb-3" v-if="fromParagraphData !== undefined">
                  <b-input-group-prepend is-text>
                    Пары вопрос-ответ
                  </b-input-group-prepend>

                  <b-form-textarea v-model="fromParagraphData.questionAnswerText" rows="5"
                                   :state="fromParagraphData.questionAnswerText > '' ? null : false"/>

                </b-input-group>
                <!--<b-input-group class="mb-3">
                  <b-input-group-prepend is-text>
                    Примеры
                  </b-input-group-prepend>

                  <b-form-textarea v-model="params.examples" rows="5"/>
                </b-input-group>-->
              </b-tab>

              <prompt-tab :messages="messages" :generation-params="generationParams"/>
            </b-tabs>
          </b-card-text>
          <b-card-footer>
            <!--            <b-alert variant="warning" :show="!linkShow && !(documentParams && params.speciality && params.discipline)">
                          Заполните обязательные поля для возможности генерации
                        </b-alert>-->
            <template v-if="documentParams.name && params.speciality && params.discipline">
              <b-btn @click="generate()" icon variant="primary" :disabled="running">
                <font-awesome-icon icon="share-from-square"/>
                Генерировать
              </b-btn>
            </template>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-tabs content-class="mt-3">
          <b-tab title="Результат" active>
            <b-card>
              <b-card-text>
                <h4>
                  Результат
                  <stopwatch style="float: right;" ref="stopwatch"/>
                </h4>
                <template v-if="result.tests || result">
                  <b-card class="question mb-2"
                          v-for="(quest, questIndex) in (result.tests || result)"
                          :key="questIndex">
                    <template #header>
                      <span>
                        {{ questIndex + 1 }}. {{ quest.question }}
                      </span>
                      <b-button-group size="sm"
                                      v-if="fromChunkData && fromChunkData.chunkId  && HAS_PERMISSION('corpus_quest_edit')">
                        <b-btn @click="createQuestInChunk(quest)"
                               title="Скопировать вопрос в чанк" variant="outline-secondary">
                          <font-awesome-icon
                              fixed-width
                              icon="clone"
                          />
                        </b-btn>
                      </b-button-group>
                    </template>
                    <div class="ml-2">

                      <p>А. {{ quest.answer }}</p>
                      <p v-for="(distractor, disIndex) in quest.distractors">
                        {{ ['Б', 'В', 'Г'][disIndex] }}. {{ distractor }}
                      </p>

                    </div>
                  </b-card>

                </template>

              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Поиск дистракторов">
            <b-card>
              <b-card-text>
                <distractor-search :theme="params.subDiscipline"/>
              </b-card-text>
            </b-card>
          </b-tab>
          <b-tab title="Поиск тестов">
            <b-card>
              <b-card-text>
                <quiz-search :theme="params.subDiscipline"/>
              </b-card-text>
            </b-card>
          </b-tab>
        </b-tabs>

      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>

#tempRangeValue div {
  width: 55px;
}

.card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>