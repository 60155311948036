<script>
import api from '@/api'

export default {
  name: 'SetPassword',
  props: {
    buttonVariant: {
      default: 'warning'
    },
    icon: {
      default: false
    },
    userId: {},
    userName: ''
  },
  data() {
    return {
      showModal: false,
      changePassData: {
        password: '',
        password_repeat: ''
      }
    }
  },
  methods: {
    changePassword() {
      let url = `/auth/change-password`;
      if (this.userId) {
        url = `/manage/users/${ this.userId }/set-password`;
      }
      api({
        method: 'post',
        url: url,
        data: this.changePassData
      })
          .then(() => {
            this.showModal = false
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}
</script>

<template>
    <b-button
        v-b-modal="`changePass-${this.userId}`"
        @click="showModal=true"
        :variant="buttonVariant"
        title="Изменить пароль"
    >
      <font-awesome-icon icon="key" fixed-width/>
      <template v-if="!icon">
        Изменить пароль
      </template>
      <b-modal
          :title="`Смена пароля` + (this.userName > '' ? ` пользователя ${this.userName}` : '')"
          :id="`changePass-${this.userId}`"
          v-model="showModal"
          centered
          width="500"
          @ok.prevent="changePassword"
      >
        <b-form>
          <b-form-group
              id="input-group-1"
              label="Новый пароль"
              label-for="pass1"
          >
            <b-form-input
                v-model="changePassData.password"
                id="pass1"
                type="password"
                autocomplete="new-password"
                :state="changePassData.password === '' ? null : changePassData.password.length > 5"
            />
            <b-form-invalid-feedback id="pass2-feedback">
              Пароль должен быть минимум 6 символов длиной
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              id="input-group-2"
              label="Подтвердите новый пароль"
              label-for="pass2"
          >
            <b-form-input
                v-model="changePassData.password_repeat"
                id="pass2"
                type="password"
                :state="changePassData.password === '' ? null: changePassData.password_repeat === changePassData.password"
            />
            <b-form-invalid-feedback id="pass2-feedback">
              Пароли не совпадают
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>

        <template #modal-footer>
          <b-btn
              variant="success"
              :disabled="changePassData.password.length < 6 || changePassData.password_repeat !== changePassData.password"
              @click="changePassword"
          >
            Сохранить
          </b-btn>
        </template>
      </b-modal>
    </b-button>
</template>

<style scoped>

</style>