<script>
import Stopwatch from "./Stopwatch.vue";
import api from '@/api'

export default {
  name: 'QuizSearch',
  components: { Stopwatch },
  data() {
    return {
      params: {
        distractor: '',
        // distractor: 'липидный спектр крови',
        count: 10
      },
      quizList: [],
      alertNoData: false,
      running: false
    }
  },
  methods: {
    search() {
      this.running = true
      this.$refs.stopwatch.reset()
      this.$refs.stopwatch.start()
      api.get(`/generator/distractor-search`, { params: this.params }).then(({data}) => {
        if (data.length === 0) this.alertNoData = true
        this.quizList = data
      }).catch(({ response }) => {
        console.log(response)
        this.$bvToast.toast('Что-то пошло не так', {
          ...this.commonToaster, variant: 'danger'
        })
      }).finally(() => {
        this.$refs.stopwatch.stop()
        this.running = false
      })
    }
  },
  watch: {}
}
</script>

<template>
  <b-container fluid style="padding: 0">
    <b-row no-gutters>
      <b-col>
        <h4>
          Поиск тестов
          <stopwatch style="float: right;" ref="stopwatch"/>
        </h4>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-2">
      <b-col cols="12">
        <b-input-group>
          <b-input-group-prepend is-text>
            Верный ответ
          </b-input-group-prepend>

          <b-form-input v-model="params.distractor"/>
          <b-input-group-append>
            <b-form-input v-model="params.count" type="number"/>
            <b-btn :disabled="running || params.distractor.trim() === ''" @click="search">
              <font-awesome-icon icon="search"></font-awesome-icon>
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <div v-for="quiz in quizList">
        <b>{{ quiz.info.system_number }}. {{ quiz.info.question }} ({{ quiz.distance }})</b>
        <ul>
          <li v-for="distractor in quiz.info.distractors">
            <span :class="{correctDistractor: distractor.is_correct}">{{ distractor.name }}</span>
          </li>
        </ul>
      </div>
      <b-alert variant="warning" :show="alertNoData"  dismissible>
        Тесты не найдены
      </b-alert>
    </b-row>

  </b-container>
</template>

<style scoped>
.correctDistractor {
  font-weight: bold;
}
</style>