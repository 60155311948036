<script>
import ButtonGeneratorMixin from '@/mixins/ButtonGeneratorMixin'

export default {
  name: 'Table2Text',
  mixins: [ButtonGeneratorMixin],
  props: {
    text: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'secondary'
    }
  },
  methods: {
    setPrompts() {
      this.messages.user = `Я предложу тебе текст в виде текстовой таблицы, \
а тебе нужно эту таблицу преобразовать в обычный, логично связанный текст. \
Вот моя текстовая таблица: "${ this.text }". Подумай и приступай`
    }
  }
}
</script>

<template>
  <b-btn :variant="variant" @click="callGenerate" :disabled="running">
    Таблицу в текст
    <template>
      <stopwatch ref="stopwatch"/>
    </template>
  </b-btn>
</template>

<style scoped>

</style>