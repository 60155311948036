export default {
  data () {
    return {
      loading: false

    }
  },
  props: {
    edit: Object
  },
  methods: {
    clear () {
    },
    save () {
      this.loading = true
      this.apiCall()
        .finally(() => this.loading = false)
    }
  },
  computed: {}
}