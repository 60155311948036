<template>
  <ul>
    <li v-for="item in nodes" class="item">
      <div>
        <template v-if="!item.id">
          {{ get_item_label(item) }}
        </template>
        <b-link v-else
                :to="{ name: 'MeshDescriptor', params: { descriptorId: item.id }}"
                :class="{highlight: highlight && highlight.includes(item.current_position)}">
          {{ get_item_label(item) }}
        </b-link>
        <span class="level-button" v-if="item.is_folder" @click="toggle(item)">
          <font-awesome-icon v-if="item.is_open" icon="minus-circle"></font-awesome-icon>
          <font-awesome-icon v-else icon="plus-circle"></font-awesome-icon>
        </span>
      </div>
      <div v-if="item.is_open">
        <mesh-level :parent="item.current_position" :highlight="highlight"></mesh-level>
      </div>
    </li>
  </ul>
</template>
<script>
  import Mesh from '.'

  export default {
    name: 'mesh-level',
    props: {
      parent: String,
      highlight: Array
    },
    data: function () {
      return {
        Mesh: Mesh,
        loading: true,
        error: false,
        nodes: []
      }
    },
    methods: {
      toggle (node) {
        if (node.is_folder) {
          node.is_open = !node.is_open;
        }
      },
      get_item_label (item) {
        return Mesh.get_item_label (item)
      }
    },
    mounted () {
      Mesh.get_level (this)
    }
  }
</script>

<style scoped>
  ul {
    padding-left: 2em;
    list-style-type: none;
  }

  .level-button {
    color: var(--primary);
    cursor: pointer;
  }

  .level-button svg {
    margin-bottom: -1px;
  }

  .highlight {
    font-weight: bold;
  }
</style>