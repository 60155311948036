<script>

import VJsoneditor from 'v-jsoneditor'
import DocumentMixin from '@/mixins/DocumentMixin'
import CorpusMixin from '@/mixins/CorpusMixin'
import api from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'CorpusSources',
  mixins: [DocumentMixin, CorpusMixin],
  components: { VJsoneditor },
  data() {
    return {
      docName: '',
      inputLink: {
        loading: false,
        start: {
          url: ''
        },
        end: {
          url: ''
        }
      },

      linkShow: true,
      parsedDoc: {
        loading: false,
        data: []
      }
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION']),
    stateInputLinkEnd() {
      if (this.inputLink.end.docId && this.inputLink.start.docId) {
        return this.inputLink.end.docId === this.inputLink.start.docId
      }
      return true
    },
    stateInputLinkStart() {
      return !!(this.inputLink.start.url && this.inputLink.start.docId && this.inputLink.start.anchor)
    }
  },
  mounted() {
    this.loadCorpusById(true)
    // this.inputLink.start.url = 'https://library.mededtech.ru/rest/documents/ISBN9785970450185/?anchor=13_13_ТЯЖЕЛАЯ_ВНЕБОЛЬНИЧНАЯ_ПНЕВМОНИЯ#13_13_ТЯЖЕЛАЯ_ВНЕБОЛЬНИЧНАЯ_ПНЕВМОНИЯ'
    // this.inputLink.end.url = 'https://library.mededtech.ru/rest/documents/ISBN9785970450185/?anchor=paragraph_8m4okr#paragraph_8m4okr'
  },
  watch: {
    corpusId() {
      this.loadCorpusById(true)
    },
    'inputLink.start.url'(url) {
      this.inputLink.start = this.parseLink(url)
    },
    'inputLink.end.url'(url) {
      this.inputLink.end = this.parseLink(url)
    }
  },
  methods: {
    callHandleDoc() {
      this.inputLink.loading = true
      this.loadDocumentData(this.inputLink.start.docId, this.inputLink.start.anchor).then(docData => {
        this.docName = docData.docName
        this.callParseDoc()
      })
          .catch((err) => {
            throw err
          })
          .finally(() => this.inputLink.loading = false)
    },
    callParseDoc() {
      this.parsedDoc.loading = true
      api.post(`/doc/${ this.inputLink.start.docId }/parse`, {
        anchors: {
          start: this.inputLink.start.anchor,
          end: this.inputLink.end.anchor
        }
      })
          .then(
              (response) => {
                // console.log(response)
                this.parsedDoc.data = response.data
                if (response.data.length === 0) {
                  this.$bvToast.toast(
                      'Парсинг вернул пустой результат. Проверьте ссылки', {
                        ...this.commonToaster, variant: 'warning'
                      }
                  )
                }
              }
          )
          .catch(({ response }) => {
            if (response.status === 404) {
              // если документа нет, он ошибка в другом месте кидается
            }
          })
          .finally(() => this.parsedDoc.loading = false)
    },

    checkDocInSources() {
      return this.corpus?.data?.sources?.map(x => x.document_id).includes(this.inputLink.start.docId)
    },
    callAppendSource() {
      this.corpus.loading = true
      api.post(`/corpus/${ this.corpus.data.id }/source`, {
        docId: this.inputLink.start.docId,
        sections: this.parsedDoc.data
      })
          .then((response) => {
            this.corpus.data = response.data
            this.$bvToast.toast('Добавлен новый источник', {
              ...this.commonToaster, variant: 'success'
            })
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              // если документа нет, он ошибка в другом месте кидается
              this.$bvToast.toast(
                  'Корпус не найден', {
                    ...this.commonToaster, variant: 'danger'
                  }
              )
              return
            }
            this.$bvToast.toast(
                'Неизвестная ошибка при добавлении источника в корпус', {
                  ...this.commonToaster, variant: 'danger'
                }
            )
            console.log(response)
          })
          .finally(() => this.corpus.loading = false)
    },
    callDeleteSource(source) {
      this.$bvModal.msgBoxConfirm(`Удалить источник "${ source.title }" из корпуса текстов "${ this.corpus.data.name }"?`, {
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        hideHeaderClose: false,
        centered: true
      }).then((confirm) => {
        if (!confirm) return
        this.corpus.loading = true
        api.delete(`/corpus/source/${ source.id }`).then(() => {
          const index = this.corpus.data.sources.indexOf(source)
          this.corpus.data.sources.splice(index, 1)
        })
            .then(() => {
              this.$bvToast.toast('Источник удален', {
                ...this.commonToaster, variant: 'success'
              })
            })
            .catch((error) => {
              if (error.response.status === 404) {
                // если документа нет, он ошибка в другом месте кидается
                this.$bvToast.toast(
                    'Источник не найден', {
                      ...this.commonToaster, variant: 'danger'
                    }
                )
                return
              }
              this.$bvToast.toast(
                  'Неизвестная ошибка при удалении источника из корпуса', {
                    ...this.commonToaster, variant: 'danger'
                  }
              )
              console.log(error)
            })
            .finally(() => this.corpus.loading = false)
      })
    }
  }
}
</script>

<template>
  <b-container fluid class="p-2">
    <b-row no-gutters>
      <b-col cols="12" md="6" class="pr-2">
        <!--Список источников-->

        <b-card v-if="corpus.data.sources" title="Список источников">
          <b-skeleton-wrapper :loading="corpus.loading">
            <template #loading>
              <b-skeleton-img no-aspect height="70vh"/>
            </template>
            <b-card-text style="height: 70vh; overflow-y: scroll;">
              <b-list-group>
                <b-list-group-item
                    v-for="(source, sourceNumber) in corpus.data.sources"
                    :key="`source-${sourceNumber}}`"
                    class="d-flex justify-content-between align-items-center"
                    :variant="source.document_id === inputLink.start.docId ? 'warning' : ''"
                >
                  <div class="flex-grow-1">
                    {{ sourceNumber + 1 }}. {{ source.title }}
                  </div>
                  <b-badge variant="dark" pill>
                    <span v-b-tooltip.hover title="Количество разделов">
                      {{ source.sections.length }} §
                    </span>
                  </b-badge>
                  <b-button-close @click="callDeleteSource(source)" v-if="HAS_PERMISSION('corpus_source_edit')"/>

                </b-list-group-item>
              </b-list-group>
            </b-card-text>
          </b-skeleton-wrapper>

        </b-card>
      </b-col>
      <b-col cols="12" md="6" v-if="corpus.data.sources">
        <b-card
            title="Добавить документ в корпус"
            sub-title="Укажите ссылки на начало и конец парсинга в документе"
            v-if="HAS_PERMISSION('corpus_source_edit')"
        >
          <b-button v-if="docName" block v-b-toggle.accordion-1 variant="secondary">
            {{ docName }}
          </b-button>
          <b-input-group class="my-2" prepend="Начало">
            <b-input v-model="inputLink.start.url" :state="stateInputLinkStart"
                     placeholder="Первый раздел, который парсится"/>
            <b-input-group-append>
              <b-btn variant="outline-primary" :disabled="!stateInputLinkStart" :href="inputLink.start.url"
                     target="_blank">
                <font-awesome-icon icon="link"/>
              </b-btn>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class="my-2" prepend="Конец">
            <b-input v-model="inputLink.end.url" :state="stateInputLinkEnd"
                     placeholder="Последний параграф который парсится"/>
            <b-input-group-append>
              <b-btn variant="outline-primary" :disabled="!(stateInputLinkEnd && inputLink.end.url)"
                     :href="inputLink.end.url"
                     target="_blank">
                <font-awesome-icon icon="link"/>
              </b-btn>
            </b-input-group-append>
          </b-input-group>

          <b-button-group v-if="stateInputLinkStart && stateInputLinkEnd">
            <b-btn
                variant="primary"
                :disabled="inputLink.loading"
                @click="callHandleDoc"
            >
              <font-awesome-icon icon="share-from-square"/>
              Обработать
            </b-btn>
            <b-btn
                variant="success"
                v-if="parsedDoc.data.length"
                :disabled="inputLink.loading || checkDocInSources()"
                @click="callAppendSource"
            >
              Вставить новый источник
            </b-btn>
          </b-button-group>
          <b-alert :show="checkDocInSources()" variant="danger">
            Этот документ уже находится в источниках
          </b-alert>
        </b-card>

        <div class="mt-2">
          <b-card title="Обработанный документ" v-if="parsedDoc.data.length || parsedDoc.loading">
            <b-skeleton-wrapper :loading="parsedDoc.loading">
              <template #loading>
                <b-skeleton-img no-aspect height="40vh"/>
              </template>
              <b-input-group>
                <v-jsoneditor
                    v-model="parsedDoc.data"
                    height="40vh"
                    :options="{
                      mainMenuBar: true,
                      statusBar: true,
                      mode: 'code',
                      language: 'ru',
                      enableSort: false
                    }"/>

              </b-input-group>
            </b-skeleton-wrapper>
          </b-card>
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>

<style>

</style>