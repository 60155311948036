<script>
import GeneratorMixin from '@/mixins/GeneratorMixin'

export default {
  name: 'Header2Distractor',
  mixins: [GeneratorMixin],
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showBlock: '',
      messages: {
        system: '',
        user: '',
        assistant: ['']
      },
    }
  },
  methods: {
    setPrompts() {
      this.messages.user = `\
      Тебе нужно принять роль эксперта по обработке медицинских текстов.
      Я дам тебе абзац из книги и тебе нужно придумать короткую фразу для заголовка этого абзаца.
      Не включай слово "Заголовок" в ответ.
      Вот этот текст: "${ this.text }".
      Подумай и приступай.
      `
    }
  },
  watch: {
    text: {
      deep: true,
      handler() {
        this.setPrompts()
      }
    },
    'messages.assistant'(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<template>
  <b-btn variant="secondary" @click="generate()" :disabled="running">
    Заголовок
    <template>
      <stopwatch ref="stopwatch"/>
    </template>
  </b-btn>
</template>

<style scoped>

</style>