import { render, staticRenderFns } from "./DivideChunk.vue?vue&type=template&id=90f8e480&scoped=true"
import script from "./DivideChunk.vue?vue&type=script&lang=js"
export * from "./DivideChunk.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90f8e480",
  null
  
)

export default component.exports