<script>
import Doc from '.'
import DocSearch from './DocSearch.vue';

export default {
  name: "Documents",
  components: { DocSearch },
  data() {
    return {
      info: {
        clinrec_other: null,
        clinrec_minzdrav: null,
        medcarestand: null,
        sanpin: null,
        book: null,
        prikaz: null
      },
      classifications: [],
      spec_array: [],
      selected_class: null,
      sortBy: 'name',
      prof_assoc: null,
      show_table: false,
      fields: [
        {
          key: "name",
          label: "Наименование",
          sortable: true
        },
        {
          key: "count",
          label: "Количество",
          sortable: true
        },
        {
          key: "url",
          label: "Сайт"
        }
      ]

    };
  },
  filters: {
    uppercase: function (v) {
      return v.toUpperCase();
    }
  },
  methods: {
    change_spec(spec) {
      Doc.get_count_doc(this, spec)
    },
    profassoc_handler(name) {
      let href = window.location.href.split('\/')[0];
      let url = ''
      if (!name.name)
        url = `${ href }?class=clinrec_other&prof_assoc=${ name.value }`;
      else
        url = `${ href }?class=clinrec_other&prof_assoc=${ name.name }`;
      window.open(url, '_blank');
    },
    select_type(id, flag) {
      if (id === 'clinrec_other' && flag === false) {
        if (this.prof_assoc === null) Doc.get_prof_assoc(this);
        this.show_table = !this.show_table;
        this.selected_class = null
      }
      else {
        this.show_table = false;
        this.selected_class = id
      }
    },
    get_data() {
      Doc.get_count_doc(this)
    }
  },
  mounted() {
    this.get_data()
    this.SPINNER_SHOW()
    this.loadSpecList()
        .then(res => this.spec_array = res)
        .catch((error) => console.log(error))
        .finally(() => (this.SPINNER_HIDE()))
    Doc.get_doc_classification(this)
    if (this.$route.query.class) {
      this.selected_class = this.$route.query.class
    }
  }
}
</script>

<template>
  <div class="container">
    <div id="count_doc">
      <b-card-group id="count_cards" deck class="mb-4">
        <b-card v-for="item in classifications"
                v-if="!item.hidden"
                :class="item.id"
                :key="item.id"
                :title="info[item.id]"
                title-tag="h3"
                :sub-title="item.name | uppercase"
                text-variant="white"
                v-on:click="select_type(item.id, false)">
        </b-card>
      </b-card-group>
    </div>

    <b-table hover small caption-top
             v-if="show_table"
             :items="prof_assoc"
             :sort-by.sync="sortBy"
             :fields="fields"
             @row-dblclicked="profassoc_handler"
    >
      <template #table-caption>
        <h4>Профессиональные сообщества
          <b-button variant="link"
                    v-if="show_table"
                    v-on:click="select_type('clinrec_other', true)">
            Просмотреть по всем
          </b-button>
        </h4>
      </template>
      <template v-slot:cell(name)="data" v-html="data.value">
        <b-button style="white-space: normal; text-align: left; color: black;" variant="link" class="p-0"
                  @click="profassoc_handler(data)">{{ data.value }}
        </b-button>
      </template>
      <template v-slot:cell(url)="data" v-html="data.value">
        <a :href="data.value" target="_blank">{{ data.value }}</a>
      </template>
    </b-table>

    <doc-search :selected_class="selected_class" :classifications="classifications" :spec_array="spec_array"
                @change_spec="change_spec"></doc-search>
  </div>
</template>

<style>
#count_doc {
  padding-top: 5%;
}

#count_doc .card {
  cursor: pointer;
  text-align: center;
}

#count_cards .card-subtitle {
  color: white !important;
  font-size: 0.8rem;
}

#count_cards .card {
  box-shadow: 2px 2px 10px #DADADA;
}

.book {
  background-color: #3b5dc9E6 !important;
}

.clinrec_minzdrav {
  background-color: #41a6f6E6 !important;
}

.clinrec_other {
  background-color: #faca30F2 !important;
}

.medcarestand {
  background-color: #ef7d57E6 !important;
}

.sanpin {
  background-color: #38b764E6 !important;
}

.prikaz {
  background-color: #257179E6 !important;
}
</style>