<script>
import DocumentMixin from '@/mixins/DocumentMixin'
import Rephrase from '@/components/Rephrase.vue'
import api from '@/api'
import Vue from 'vue'
import Table2Text from '@/components/Table2Text.vue'
import CardMixin from '@/mixins/CardMixin'
import { mapGetters } from 'vuex'
import DocumentAbbrevs from '@/components/DocumentAbbrevs.vue'

export default {
  mixins: [DocumentMixin, CardMixin],
  components: { DocumentAbbrevs, Table2Text, Rephrase },
  props: {
    edit: Object
  },
  data() {
    return {
      isNew: false,
      formData: {
        title: '',
        text: '',
        link: '',
        kind: null
      }
    }
  },
  methods: {
    onShow() {
      if (!this.edit.editChunk.id) {
        this.isNew = true
        return
      }
      this.isNew = false

      this.formData.title = this.edit.editChunk.title
      this.formData.text = this.edit.editChunk.text
      this.formData.kind = this.edit.editChunk.kind
      this.formData.link = this.$parent.calcChunkLink(this.edit.editChunk, this.$parent.selectedSource)
    },
    generatorTextHandler(text) {
      this.formData.text = text
    },
    clickRevealAbbrev() {
      this.revealAbbrev(this.$parent.selectedSource.document_id, this.formData.text)
          .then(response => {
            this.generatorTextHandler(response)
          })
    },
    async apiCall() {
      if (!this.isNew) {
        // Редактирование чанка
        return api.post(`/corpus/chunk/${ this.edit.editChunk.id }`, {
          ...this.formData,
          anchor: this.urlAnchor
        })
            .then(({ data }) => {
              Vue.set(this.edit.editChunk, 'title', data.title)
              Vue.set(this.edit.editChunk, 'text', data.text)
              Vue.set(this.edit.editChunk, 'anchor', data.anchor)
              Vue.set(this.edit.editChunk, 'kind', data.kind)
              this.$bvToast.toast(
                  'Чанк сохранен', { ...this.commonToaster, variant: 'success' }
              )
              this.$bvModal.hide('modal-edit-chunk')
              this.$parent.eraseSelection()
            })
            .catch(error => {
              console.log(error)
              this.$bvToast.toast('Какая-то ошибка', {
                ...this.commonToaster, variant: 'danger'
              })
            })
      }
      else {
        // Создание чанка
        return api.post(`/corpus/section/${ this.edit.section.id }/chunk`, {
          title: this.formData.title,
          text: this.formData.text,
          kind: this.formData.kind,
          anchor: this.urlAnchor
        })
            .then(({ data }) => {
              this.edit.section.chunks.push(data)
              this.$bvToast.toast(
                  'Чанк создан', { ...this.commonToaster, variant: 'success' }
              )
              this.$bvModal.hide('modal-edit-chunk')
            })
            .catch(error => {
              console.log(error)
              this.$bvToast.toast('Какая-то ошибка', {
                ...this.commonToaster, variant: 'danger'
              })
            })
      }
    },
    clear() {
      this.formData.title = this.formData.text = this.formData.link = ''
      this.formData.kind = null
      delete this.edit.editChunk
      delete this.edit.section
    }
  },
  computed: {
    ...mapGetters(['HAS_PERMISSION', 'CHUNK_ICONS']),
    cardTitle() {
      if (!this.isNew) {
        return `Редактирование чанка "${ this.edit?.editChunk?.title || 'Без заголовка' }"`
      }
      else {
        return `Создание чанка в разделе "${ this.edit?.section?.title }"`
      }
    },
    stateText() {
      return this.formData.text.length >= 10
    },
    urlAnchor() {
      return this.parseLink(this.formData.link).anchor
    },
    stateLink() {
      if (!this.$parent.selectedSource) {
        return true
      }
      const docUrl = `${ process.env.VUE_APP_DOCUMENTS_ROOT }/${ this.$parent.selectedSource?.document_id }`
      if (!this.formData.link.startsWith(docUrl)) {
        return false
      }
      return !!this.urlAnchor

    }
  }
}
</script>

<template>
  <b-modal
      id="modal-edit-chunk"
      :title="cardTitle"
      lazy static centered scrollable ok-only no-close-on-backdrop
      size="lg"
      @ok.prevent="save"
      @close="clear"
      @show="onShow"
      :busy="!(stateText && stateLink) || loading"
  >

    <b-form-group label="Заголовок">
      <b-input trim v-model="formData.title"/>
    </b-form-group>

    <b-form-group label="Текст">
      <b-textarea trim v-model="formData.text" rows="5" :state="stateText"/>
      <b-button-group>
        <table2-text :text="formData.text" @input="generatorTextHandler" class="mr-1" :disabled="loading"/>
        <rephrase :text="formData.text" @input="generatorTextHandler" class="mr-1" :disabled="loading"/>
        <b-btn @click="clickRevealAbbrev" :disabled="loading">Замена аббревиатур</b-btn>
      </b-button-group>
    </b-form-group>

    <b-form-group
        label="Ссылка"
        label-for="modal-edit-par-link"
    >
      <b-textarea id="modal-edit-par-link" trim v-model="formData.link" :state="stateLink"/>
      <b-form-invalid-feedback id="edit-par-link-feedback" v-if="$parent.selectedSource">
        Ссылка должна вести на документ <i>"{{ $parent.selectedSource.title }}"</i> и ссылаться на конкретный
        параграф
      </b-form-invalid-feedback>
    </b-form-group>

    <b-input-group label="Тип чанка">
      <b-input-group-prepend is-text v-if="formData.kind">
        <font-awesome-icon :icon="CHUNK_ICONS[formData.kind].icon"/>
      </b-input-group-prepend>

      <b-select v-model="formData.kind">
        <option v-for="(option, key) in CHUNK_ICONS" :value="key">
          {{ option.tooltip }}
        </option>
      </b-select>

      <b-input-group-append>
        <b-btn @click="formData.kind = null" :disabled="loading">
          <font-awesome-icon icon="xmark"/>
        </b-btn>
      </b-input-group-append>
    </b-input-group>

    <template #modal-footer="{ ok }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <document-abbrevs :doc-id="$parent.selectedSource.document_id" title="Показать аббревиатуры документа"/>
      <b-button variant="primary" @click="ok()">
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<style scoped>

</style>