<script>
import api from '@/api'
import Vue from 'vue'
import CardMixin from '@/mixins/CardMixin'

export default {
  mixins: [CardMixin],
  props: {
    edit: Object
  },
  data() {
    return {
      formData: {
        title: ''
      }
    }
  },
  methods: {
    onShow() {
      this.formData.title = this.edit.editSection.title
    },
    async apiCall() {
      return api.post(`/corpus/section/${ this.edit.editSection.id }`, {
        ...this.formData
      })
          .then(({ data }) => {
            Vue.set(this.edit.editSection, 'title', data.title)
            this.$bvToast.toast(
                'Раздел сохранен', { ...this.commonToaster, variant: 'success' }
            )
            this.$bvModal.hide('modal-edit-section')
          })
          .catch(error => {
            console.log(error)
            this.$bvToast.toast('Какая-то ошибка', {
              ...this.commonToaster, variant: 'danger'
            })
          })

      // this.clear()
    },
    clear() {
      this.formData.title = ''
      delete this.edit.editSection
    }
  },
  computed: {
    stateTitle() {
      return this.formData.title.length >= 1
    }
  }
}
</script>

<template>
  <b-modal
      id="modal-edit-section"
      :title="`Редактирование раздела '${(edit.editSection ? edit.editSection.title : undefined) || 'Без заголовка'}'`"
      lazy static centered scrollable ok-only no-close-on-backdrop
      size="lg"
      ok-title="Сохранить"
      cancel-title="Отмена"
      @ok.prevent="save"
      @close="clear"
      @show="onShow"
      :busy="!stateTitle || loading"
  >

    <b-form-group
        label="Заголовок"
    >
      <b-input trim v-model="formData.title" :state="stateTitle"/>
    </b-form-group>
  </b-modal>
</template>

<style scoped>

</style>